import { API_METHOD, API_STATUS, API_URL } from "constants/apiResponse"
import { CONTENT_TYPES } from "constants/appConstants"
import fetchUtil from "utils/fetchUtil"

export const registerUserApi = async body => {
  return fetchUtil({
    url: API_URL.AUTH.SIGNUP,
    method: API_METHOD.POST,
    body: body,
    contentType: CONTENT_TYPES.FORM_DATA,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const updateUserApi = body => () => {
  return fetchUtil({
    url: API_URL.AUTH.UPDATE,
    method: API_METHOD.POST,
    body: body,
    contentType: CONTENT_TYPES.FORM_DATA,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
