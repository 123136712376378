import React from "react"

import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Label,
  Input,
  FormFeedback,
  FormGroup,
  Button,
} from "reactstrap"
import { useFormik } from "formik"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import * as Yup from "yup"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useState } from "react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { SavePageApi, EditArticleCategoryApi } from "../../redux/actions"

const EditCategory = () => {
  const dispatch = useDispatch()
  const [editData, setEditData] = useState({})
  const { id } = useParams()

  useEffect(() => {
    getCategoryData()
  }, [])

  const getCategoryData = () => {
    dispatch(EditArticleCategoryApi(id))
      .then(val => {
        setEditData(val.data)
      })
      .catch(e => {})
  }

  const handleEditPageSubmit = values => {
    let payload = {
      id: Number(id),
      name: values?.name,
      description: values?.description,
    }

    dispatch(SavePageApi(payload)).catch(e => {})
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: editData?.name || "",
      color: editData?.color || "black",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Page Name"),
      //   description: Yup.string().required("Please Enter Page Name"),
    }),
    onSubmit: handleEditPageSubmit,
  })

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target

    let list = [...editData?.extras?.faq] || []
    list[index] = {
      ...list[index],
      [name]: value,
    }

    setEditData({
      ...editData,
      extras: {
        faq: [...list],
      },
    })
  }

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...editData?.extras?.faq]
    list.splice(index, 1)
    setEditData({ ...editData, extras: { faq: list } })
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setEditData({
      ...editData,
      extras: {
        faq: [...editData?.extras?.faq, { name: "", content: "" }],
      },
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Edit Page" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="col-12">
                      <div className="row">
                        <div className={`col-sm-12 "col-md-12"`}>
                          <FormGroup>
                            <Label htmlFor="validationCustom01">Name</Label>
                            <Input
                              name="name"
                              placeholder="Name"
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              // onChange={e => setPageName(e.target.value)}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                            validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor="validationCustom01">Color</Label>
                            <Input
                              name="color"
                              type="color"
                              className="form-control form-control-color"
                              id="validationCustom01"
                              // onChange={e => setPageName(e.target.value)}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.color || ""}
                              invalid={
                                validation.touched.color &&
                                validation.errors.color
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.color &&
                            validation.errors.color ? (
                              <FormFeedback type="invalid">
                                {validation.errors.color}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </div>
                      </div>
                    </div>

                    <Button color="primary" type="submit">
                      Submit form
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditCategory
