import React from "react"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "reactstrap"
import { useDispatch } from "react-redux"
import { ArticleCategoryListApi } from "../../redux/actions"
import { columns } from "./constant.js"
import moment from "moment/moment"

const ArticleCategoryList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [datatableValue, setDatatableValue] = useState([])
  useEffect(() => {
    getArticleCategories()
  }, [])

  const getArticleCategories = () => {
    dispatch(ArticleCategoryListApi())
      .then(response => {
        let data = []
        if (response?.length) {
          for (const item of response) {
            data.push({
              action: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="btn action-btn"
                    onClick={() =>
                      navigate("/dashboard/article-categories/" + item.id)
                    }
                  >
                    <i
                      style={{ fontSize: 20 }}
                      className="mdi mdi-pencil action-icon color-green"
                    />
                  </button>
                </div>
              ),
              label: item.label,
              created_at: moment(item.created_at).format("MMMM Do YYYY"),
            })
          }
          setDatatableValue(data)
        } else {
          setDatatableValue([])
        }
      })
      .catch(e => {
        console.log(`ERROR IN GETTING getArticleCategories: `, e)
      })
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs />
        <TableContainer
          columns={columns}
          data={datatableValue}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          className="custom-header-css"
          isDropdown={true}
          // addNewHeading="Add New Article Category"
          tableHeading="Articles Categories Listing"
          handleAddClicks={() => console.log("add clickedddd")}
        />
      </div>
      {/* <DeleteModal
        show={deleteModal}
        onCloseClick={() => toggleDeleteModal()}
        onDeleteClick={() => deleteArticle()}
      /> */}
    </div>
  )
}

export default ArticleCategoryList
