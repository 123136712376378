import React, { useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, FormFeedback, Input, InputGroup, Label } from "reactstrap"
import { useNavigate } from "react-router"
import { useDispatch } from "react-redux"
import * as Yup from "yup"
import { useFormik } from "formik"
import { REGEX_VALIDATION } from "constants/appConstants"
import { ChangePasswordApi } from "redux/actions"

const ChangePassword = () => {
  document.title = "Login | The Five Spheres of Fit"
  const [passwordBox, setPasswordBox] = useState({
    old_password: true,
    new_password: true,
    confirm_password: true,
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleChangePasswordApi = values => {
    dispatch(ChangePasswordApi(values))
      .then(val => {
        navigate("/dashboard", { replace: true })
      })
      .catch(e => {})
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string()
        .required("Enter old Password")
        .min(8, "Must be 8 characters long.")
        .matches(
          REGEX_VALIDATION.PASSWORD_VALIDATOR,
          "Invalid Password, eg: P@ssword1"
        ),
      new_password: Yup.string()
        .required("Enter New Password")
        .min(8, "Must be 8 characters long.")
        .matches(
          REGEX_VALIDATION.PASSWORD_VALIDATOR,
          "Invalid Password, eg: P@ssword1"
        ),
      confirm_password: Yup.string()
        .required(`Enter Confirm Password`)
        .oneOf([Yup.ref("new_password"), null], "Passwords must match."),
    }),
    onSubmit: handleChangePasswordApi,
    // },
  })

  const onViewPasswordClick = key => {
    setPasswordBox({
      ...passwordBox,
      [key]: !passwordBox[key],
    })
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs />

        <div
          className="d-flex"
          style={{
            height: "calc(100vh - 200px)",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="card" style={{ width: 455 }}>
            <div className="card-body">
              <div className="p-2">
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    validation.handleSubmit()
                    return false
                  }}
                >
                  <h2 className="auth-heading">Change Password</h2>

                  <div className="mb-3">
                    <Label className="form-label">Old Password</Label>
                    <InputGroup>
                      <Input
                        name="old_password"
                        value={validation.values.old_password || ""}
                        type={passwordBox?.old_password ? "password" : "text"}
                        placeholder="Enter Old Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.old_password &&
                          validation.errors.old_password
                            ? true
                            : false
                        }
                      />
                      <button
                        className="input-group-append"
                        type="button"
                        onClick={() => onViewPasswordClick("old_password")}
                        style={{
                          border: "none",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <span className="input-group-text">
                          {passwordBox?.old_password ? (
                            <i className="mdi mdi-eye" />
                          ) : (
                            <i className="mdi mdi-eye-off" />
                          )}
                        </span>
                      </button>

                      {validation.touched.old_password &&
                      validation.errors.old_password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.old_password}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">New Password</Label>
                    <InputGroup>
                      <Input
                        name="new_password"
                        value={validation.values.new_password || ""}
                        type={passwordBox?.new_password ? "password" : "text"}
                        placeholder="Enter New Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.new_password &&
                          validation.errors.new_password
                            ? true
                            : false
                        }
                      />
                      <button
                        className="input-group-append"
                        type="button"
                        onClick={() => onViewPasswordClick("new_password")}
                        style={{
                          border: "none",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <span className="input-group-text">
                          {passwordBox?.new_password ? (
                            <i className="mdi mdi-eye" />
                          ) : (
                            <i className="mdi mdi-eye-off" />
                          )}
                        </span>
                      </button>
                      {validation.touched.new_password &&
                      validation.errors.new_password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.new_password}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Confirm New Password</Label>
                    <InputGroup>
                      <Input
                        name="confirm_password"
                        value={validation.values.confirm_password || ""}
                        type={
                          passwordBox?.confirm_password ? "password" : "text"
                        }
                        placeholder="Enter Confirm Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.confirm_password &&
                          validation.errors.confirm_password
                            ? true
                            : false
                        }
                      />
                      <button
                        className="input-group-append"
                        type="button"
                        onClick={() => onViewPasswordClick("confirm_password")}
                        style={{
                          border: "none",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <span className="input-group-text">
                          {passwordBox?.confirm_password ? (
                            <i className="mdi mdi-eye" />
                          ) : (
                            <i className="mdi mdi-eye-off" />
                          )}
                        </span>
                      </button>
                      {validation.touched.confirm_password &&
                      validation.errors.confirm_password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.confirm_password}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </div>

                  <div className="mt-3 d-grid">
                    <button className="btn btn-dark btn-block" type="submit">
                      Change Password
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
