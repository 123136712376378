const gendersArray = [
    {
        id: '1',
        label: 'Female',
    },
    {
        id: '2',
        label: 'Male',
    },
    {
        id: '3',
        label: 'Transgender',
    },
    {
        id: '4',
        label: 'Non-binary/Non-conforming',
    },
    {
        id: '5',
        label: 'Prefer not to respond',
    },
];

export default gendersArray