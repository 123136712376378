import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormFeedback,
  Input,
  Row,
} from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { APP_INFO } from "constants/appConstants"
import formatBytes from "helpers/formatBytes"
import FormButton from "components/Common/FormButton"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  ArticleCategoryListApi,
  EditViewArticleApi,
  SaveArticleApi,
  updateArticle,
} from "redux/actions"
import { useDispatch } from "react-redux"
import moment from "moment"
import { formatedDate, getUtcDate, INVALID_DATE } from "utils/dateUtil"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
const ArticleForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [categories, setCategories] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const handleFormSubmit = val => {
    setLoading(true)
    if (!selectedFiles[0] && !id) {
      validation.errors.file = "Image is required"
      setLoading(false)
      return
    }
    let formData = new FormData()

    for (let key in val) {
      if (key === "posted_at") {
        val[key] = getUtcDate(val[key])
      }
      formData.append(key, val[key])
    }

    if (selectedFiles.length > 0) {
      formData.append("file", selectedFiles[0])
    }

    if (id) {
      //payload will be given to updateUser Api
      delete formData.delete("image_url")
      formData.append("id", id)
      dispatch(updateArticle(formData))
        .then(value => {
          navigate("/dashboard/articles")
        })
        .finally(() => setLoading(false))
    } else {
      dispatch(SaveArticleApi(formData))
        .then(value => {
          navigate("/dashboard/articles")
        })
        .finally(() => setLoading(false))
    }
  }

  const handleAcceptedFiles = files => {
    if (!files[0].type.includes("image")) {
      validation.errors.file = "Only image files are allowed"
      return
    } else if (files[0].size > 5000000) {
      validation.errors.file = "File size should be less than 5MB"
      return
    } else {
      delete validation.errors.file
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setselectedFiles(files)
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      description: "",
      status: "",
      // seo_metadata: "",
      short_description: "",
      category_id: "",
      posted_at: "",
      youtube_url: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter article title"),
      status: Yup.string().required("Please select article status"),
      category_id: Yup.string().required("Please select article category"),
      description: Yup.string().required("Please enter description"),
      short_description: Yup.string().required(
        "Please enter short description"
      ),
      posted_at: Yup.string().required("Please select article posted date"),
      youtube_url: Yup.string().matches(
        /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/,
        "Please enter valid youtube url"
      ),
    }),
    onSubmit: handleFormSubmit,
  })

  useEffect(() => {
    getArticleCategories()
    if (id) {
      getArticleData(id)
      document.title = "Edit Article | " + APP_INFO.NAME
    }
  }, [])

  const getArticleData = id => {
    dispatch(EditViewArticleApi(id)).then(value => {
      let title = value.data.title
      let status = value.data.is_active
      let description = value.data.description
      let category_id = value.data.category_id
      let posted_at = value.data.created_at
      let short_description = value.data.short_description
      let image_url = value.data.image_url
      let youtube_url = value.data.youtube_url
      validation.setFieldValue("title", title)
      validation.setFieldValue("status", status)
      validation.setFieldValue("description", description)
      validation.setFieldValue("category_id", category_id)
      validation.setFieldValue("posted_at", posted_at)
      validation.setFieldValue("short_description", short_description)
      validation.setFieldValue("image_url", image_url)
      validation.setFieldValue("youtube_url", youtube_url)
    })
  }

  const getArticleCategories = () => {
    dispatch(ArticleCategoryListApi())
      .then(response => setCategories(response))
      .catch(e => {})
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={id ? "Edit Article" : "Add new Article"} />
        <form
          onSubmit={e => {
            e.preventDefault()

            validation.handleSubmit()
            return false
          }}
        >
          <Card>
            <CardBody>
              <CardTitle>Article Information</CardTitle>
              <Row>
                <Col md={6}>
                  <div className="my-2">
                    <label className="form-label">Article Title</label>
                    <Input
                      name="title"
                      className="form-control"
                      placeholder="Enter article title"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.title || ""}
                      invalid={Boolean(
                        validation.touched.title && validation.errors.title
                      )}
                    />
                    {validation.touched.title && validation.errors.title ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.title}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="my-2">
                    <label className="form-label">Article Category</label>
                    <Input
                      name="category_id"
                      className="form-control"
                      placeholder="Select category"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.category_id || ""}
                      type="select"
                      invalid={Boolean(
                        validation.touched.category_id &&
                          validation.errors.category_id
                      )}
                    >
                      <option value="">Select Category</option>
                      {categories?.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.label}
                        </option>
                      ))}
                    </Input>
                    {validation.touched.category_id &&
                    validation.errors.category_id ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.category_id}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="my-2">
                    <label className="form-label">Posted At</label>
                    {/* <Input
                      name="posted_at"
                      className="form-control"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={formatedDate(validation.values.posted_at)}
                      onKeyDown={e => {
                        e.preventDefault(e)
                        return false
                      }}
                      min={"1970-01-01"}
                      max={moment().format("yyyy-MM-DD")}
                      invalid={Boolean(
                        validation.touched.posted_at &&
                          validation.errors.posted_at
                      )}
                    /> */}
                    <DatePicker
                      showIcon
                      showPopperArrow={false}
                      showYearDropdown
                      showDisabledMonthNavigation={false}
                      className="form-control"
                      maxDate={moment().toDate()}
                      onKeyDown={e => e.preventDefault()}
                      selected={
                        validation.values.posted_at &&
                        validation.values.dob != INVALID_DATE
                          ? moment(validation.values.posted_at).toDate()
                          : moment().toDate()
                      }
                      onChange={date =>
                        validation.handleChange({
                          target: { name: "posted_at", value: date },
                        })
                      }
                      onBlur={date =>
                        validation.handleBlur({
                          target: { name: "posted_at", value: date },
                        })
                      }
                    />
                    {validation.touched.posted_at &&
                    validation.errors.posted_at ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.posted_at}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>

                <Col md={6}>
                  <div className="my-2">
                    <label className="form-label">Youtube URL</label>
                    <Input
                      name="youtube_url"
                      className="form-control"
                      placeholder="Select youtube url"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.youtube_url}
                      invalid={Boolean(
                        validation.touched.youtube_url &&
                          validation.errors.youtube_url
                      )}
                    />
                    {validation.touched.youtube_url &&
                    validation.errors.youtube_url ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.youtube_url}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="my-2">
                    <label className="form-label">Status</label>
                    <Input
                      name="status"
                      className="form-control"
                      placeholder="Select status"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.status}
                      type="select"
                      invalid={Boolean(
                        validation.touched.status && validation.errors.status
                      )}
                    >
                      <option value="">Select Status</option>
                      <option value={1}>Active</option>
                      <option value={0}>Inactive</option>
                    </Input>
                    {validation.touched.status && validation.errors.status ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.status}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="my-2">
                    <label className="form-label">Short Description</label>
                    <Input
                      name="short_description"
                      className="form-control"
                      placeholder="Enter short description"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.short_description || ""}
                      type="textarea"
                      invalid={Boolean(
                        validation.touched.short_description &&
                          validation.errors.short_description
                      )}
                    />
                    {validation.touched.short_description &&
                    validation.errors.short_description ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.short_description}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <CardTitle>Article Image</CardTitle>
              <Dropzone
                onDrop={acceptedFiles => {
                  handleAcceptedFiles(acceptedFiles)
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div
                      className="dz-message needsclick mt-2"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h4>Drop files here or click to upload image.</h4>
                      <p className="text-muted" style={{ fontSize: 12 }}>
                        Picture should be in the standard format PNG, JPEG
                      </p>
                      <p className="text-danger" style={{ fontSize: 12 }}>
                        {validation.errors.file}
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
              <Row>
                <Col md="6">
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2 cross-icon">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-lg rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                            <div
                              style={{
                                cursor: "pointer",
                                width: 30,
                                height: 30,
                              }}
                              onClick={() => setselectedFiles([])}
                            >
                              <i
                                style={{ fontSize: 15, padding: 5 }}
                                className="bx bxs-x-circle"
                              ></i>
                            </div>
                          </div>
                        </Card>
                      )
                    })}
                    {selectedFiles.length === 0 &&
                      validation.values.image_url && (
                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-lg rounded bg-light"
                                  src={validation.values.image_url}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <CardTitle className="h4">Article Description</CardTitle>
              <CKEditor
                config={{
                  removePlugins: ["MediaEmbed"],
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "|",
                    "outdent",
                    "indent",
                    "|",
                    "blockQuote",
                    "undo",
                    "redo",
                  ],
                }}
                editor={ClassicEditor}
                data={validation.values.description}
                onChange={(event, editor) => {
                  const data = editor.getData()
                  validation.setFieldValue("description", data)
                }}
              />
              <p
                className="text-danger"
                style={{ fontSize: 12, marginTop: 20 }}
              >
                {validation.errors.description}
              </p>
            </CardBody>
          </Card>

          <div style={{ display: "flex", marginBottom: 20 }}>
            <FormButton
              isLoading={loading}
              type="button"
              onClick={() => {
                if (!selectedFiles[0] && !id) {
                  validation.setFieldError("file", "Image is required")
                } else {
                  validation.submitForm()
                }
              }}
              className="btn btn-default"
              style={{
                backgroundColor: "#2A3042",
                color: "#fff",
                minWidth: "120px",
              }}
            >
              {id ? "Update" : "Create"}
            </FormButton>
            <button
              onClick={() => navigate("/dashboard/articles")}
              type="button"
              className="btn btn-default"
              style={{
                backgroundColor: "#74788D",
                color: "#fff",
                minWidth: "120px",
                marginLeft: 15,
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default ArticleForm
