import { appendQueryParams } from "../../utils/urlUtils"
import fetchUtil from "../../utils/fetchUtil"
import { API_METHOD, API_STATUS, API_URL } from "constants/apiResponse"
import {
    NOTIFICATION_LIST,
    UPDATE_CURRENT_PAGE,
    REFRESH_NOTIFICATION_LIST
} from "redux/constant/authConstant"

export const NotificationListApi =
    (page = 1, limit = 10, isDispatching = true, refresh = false) =>
        dispatch => {
            return fetchUtil({
                url: appendQueryParams(API_URL.NOTIFICATIONS.LIST, { page, limit }),
                method: API_METHOD.GET,
                showSuccessToast: false,
            })
                .then(response => {
                    if (response.statusCode == API_STATUS.OK) {
                        if (isDispatching) {
                            if (!refresh) {

                                dispatch({
                                    type: NOTIFICATION_LIST,
                                    payload: {
                                        notifications: response.data.data,
                                        currentPage: page
                                    }
                                })
                                if (response.data.data.length > 0) {
                                    dispatch({
                                        type: UPDATE_CURRENT_PAGE,
                                        payload: {
                                            currentPage: page
                                        }
                                    })
                                }
                            }
                            else {
                                dispatch({
                                    type: REFRESH_NOTIFICATION_LIST,
                                    payload: {
                                        notifications: response.data.data,
                                        currentPage: page
                                    }
                                })
                                dispatch({
                                    type: UPDATE_CURRENT_PAGE,
                                    payload: {
                                        currentPage: 1
                                    }
                                })
                            }
                        }
                        return Promise.resolve(response.data.data)
                    } else {
                        return Promise.reject(response.message)
                    }
                })
                .catch(err => {
                    return Promise.reject(err)
                })
        }