import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  InputGroup,
} from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import { connect } from "react-redux"
import { NewPasswordApi } from "../../redux/actions/index"
import { REGEX_VALIDATION } from "constants/appConstants"
import FormButton from "components/Common/FormButton"

const NewPassword = props => {
  //meta title
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const [passwordBox, setPasswordBox] = useState({
    new_password: true,
    confirm_password: true,
  })
  const { NewPasswordApi } = props

  const handleNewPassword = values => {
    setLoading(true)
    let payload = {
      new_password: values.new_password,
      token: Number(location?.state?.token),
    }
    NewPasswordApi(payload)
      .then(() => {
        navigate("/login", { replace: true })
      })
      .catch(e => {})
      .finally(() => setLoading(false))
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      new_password: Yup.string()
        .required("Please Enter Your New Password")
        .min(8, "Must be 8 characters long.")
        .matches(
          REGEX_VALIDATION.PASSWORD_VALIDATOR,
          "Invalid Password, eg: P@ssword1"
        ),
      confirm_password: Yup.string()
        .required(`Please Enter Your Confirm Password`)
        .oneOf([Yup.ref("new_password"), null], "Passwords must match."),
    }),
    onSubmit: handleNewPassword,
  })

  const onViewPasswordClick = key => {
    setPasswordBox({
      ...passwordBox,
      [key]: !passwordBox[key],
    })
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages-auth">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <h2 className="auth-heading">Reset Password</h2>
                      <div className="mb-3">
                        <Label className="form-label">New Password</Label>
                        <InputGroup>
                          <Input
                            name="new_password"
                            className="form-control"
                            placeholder="Enter New Password"
                            type={
                              passwordBox?.new_password ? "password" : "text"
                            }
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.new_password || ""}
                            invalid={
                              validation.touched.new_password &&
                              validation.errors.new_password
                                ? true
                                : false
                            }
                          />
                          <button
                            className="input-group-append"
                            type="button"
                            onClick={() => onViewPasswordClick("new_password")}
                            style={{
                              border: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <span className="input-group-text">
                              {passwordBox?.new_password ? (
                                <i className="mdi mdi-eye" />
                              ) : (
                                <i className="mdi mdi-eye-off" />
                              )}
                            </span>
                          </button>
                          {validation.touched.new_password &&
                          validation.errors.new_password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.new_password}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Confirm Password</Label>
                        <InputGroup>
                          <Input
                            name="confirm_password"
                            className="form-control"
                            placeholder="Enter Confirm Password"
                            type={
                              passwordBox?.confirm_password
                                ? "password"
                                : "text"
                            }
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirm_password || ""}
                            invalid={
                              validation.touched.confirm_password &&
                              validation.errors.confirm_password
                                ? true
                                : false
                            }
                          />
                          <button
                            className="input-group-append"
                            type="button"
                            onClick={() =>
                              onViewPasswordClick("confirm_password")
                            }
                            style={{
                              border: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <span className="input-group-text">
                              {passwordBox?.confirm_password ? (
                                <i className="mdi mdi-eye" />
                              ) : (
                                <i className="mdi mdi-eye-off" />
                              )}
                            </span>
                          </button>
                          {validation.touched.confirm_password &&
                          validation.errors.confirm_password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.confirm_password}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </div>
                      <Row className="mb-3">
                        <Col className="mt-3 d-grid">
                          <FormButton
                            isLoading={loading}
                            className="btn btn-dark w-md "
                            type="submit"
                          >
                            Reset Password
                          </FormButton>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({})
const mapDispatchToProps = {
  NewPasswordApi,
}
export default connect(mapStateToProps, mapDispatchToProps)(NewPassword)
