export const columns = [
  {
    Header: "Title",
    accessor: "label",
  },

  {
    Header: "Created At",
    accessor: "created_at",
  },

  {
    Header: "Action",
    accessor: "action",
  },
]
