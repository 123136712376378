import { toast } from "react-toastify"

export const ErrorToaster = msg => {
  if (typeof msg !== "string") {
    return
  }
  toast.error(msg || "Something went wrong!")
}

export const SuccessToastr = msg => {
  if (typeof msg !== "string") {
    return
  }
  toast.success(msg || "Operation Completed")
}
