import { appendQueryParams } from "../../utils/urlUtils"
import fetchUtil from "../../utils/fetchUtil"
import { API_METHOD, API_STATUS, API_URL } from "constants/apiResponse"
import { CONTENT_TYPES } from "constants/appConstants"
import {
  ARTICLE_LISTING,
  ARTICLE_LISTING_META,
} from "redux/constant/authConstant"

export const ArticleListApi = (page = 1, limit = 10, search = "", isDispatching = true, signal) => dispatch => {
    return fetchUtil({
      url: appendQueryParams(API_URL.ARTICLES.LIST, { page, limit, search }),
      method: API_METHOD.GET,
      showSuccessToast: false,
      signal
    })
      .then(response => {
        if (response.statusCode == API_STATUS.OK) {
          if (isDispatching) {
            dispatch({
              type: ARTICLE_LISTING_META,
              payload: response.data.meta,
            })
            dispatch({ type: ARTICLE_LISTING, payload: response.data.data })
          }
          return Promise.resolve(response.data.data)
        } else {
          return Promise.reject(response.message)
        }
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }

export const EditViewArticleApi = id => () => {
  return fetchUtil({
    url: API_URL.ARTICLES.EDIT + "/" + id,
    method: API_METHOD.GET,
    showSuccessToast: false,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const SaveArticleApi = body => () => {
  return fetchUtil({
    url: API_URL.ARTICLES.SAVE,
    method: API_METHOD.POST,
    body,
    contentType: CONTENT_TYPES.FORM_DATA,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
export const deleteArticleById = id => () => {
  return fetchUtil({
    url: API_URL.ARTICLES.DELETE + "/" + id,
    method: API_METHOD.DELETE,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const updateArticle = body => () => {
  return fetchUtil({
    url: API_URL.ARTICLES.UPDATE,
    method: API_METHOD.POST,
    body,
    contentType: CONTENT_TYPES.FORM_DATA,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const toggleStatusArticle = body => () => {
  return fetchUtil({
    url: API_URL.ARTICLES.STATUS,
    method: API_METHOD.POST,
    body,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
export const BuilkArticleDelete = body => () => {
  return fetchUtil({
    url: API_URL.ARTICLES.BULK,
    method: API_METHOD.POST,
    body,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
