import fetchUtil from "../../utils/fetchUtil"
import { API_METHOD, API_STATUS, API_URL } from "constants/apiResponse"
import {
  CHALLENGE_LISTING,
  CHALLENGE_LISTING_META,
} from "redux/constant/authConstant"
import { appendQueryParams } from "utils/urlUtils"

export const ChallengeListApi =
  (page = 1, limit = 10, search = "", signal) =>
  dispatch => {
    return fetchUtil({
      url: appendQueryParams(API_URL.CHALLENGE.LIST, { page, limit, search }),
      method: API_METHOD.GET,
      showSuccessToast: false,
      signal
    })
      .then(response => {
        if (response.statusCode == API_STATUS.OK) {
          dispatch({
            type: CHALLENGE_LISTING_META,
            payload: response.data.meta,
          })
          dispatch({ type: CHALLENGE_LISTING, payload: response.data.data })
          return Promise.resolve(response.data.data)
        } else {
          return Promise.reject(response.message)
        }
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }
export const ChallengeViewByIdApi = id => () => {
  return fetchUtil({
    url: API_URL.CHALLENGE.VIEW + "/" + id,
    method: API_METHOD.GET,
    showSuccessToast: false,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const ChallengeEditByIdApi = (id, body) => () => {
  return fetchUtil({
    url: API_URL.CHALLENGE.EDIT + "/" + id,
    method: API_METHOD.POST,
    body,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const ChallengeDeleteByIdApi = id => () => {
  return fetchUtil({
    url: API_URL.CHALLENGE.DELETE + "/" + id,
    method: API_METHOD.DELETE,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const ChallengeAddApi = body => () => {
  return fetchUtil({
    url: API_URL.CHALLENGE.ADD,
    method: API_METHOD.POST,
    body,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
