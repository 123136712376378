export const columns = [
  {
    Header: "Checkbox",
    accessor: "checkbox",
  },
  {
    Header: "User Name",
    accessor: "name",
  },
  {
    Header: "Email Address",
    accessor: "email",
  },
  {
    Header: "Phone Number",
    accessor: "phone",
  },
  {
    Header: "Created At",
    accessor: "created_at",
  },
  {
    Header: "OTP",
    accessor: "token",
  },
  {
    Header: "User Verified",
    accessor: "user_verified",
  },

  {
    Header: "Status",
    accessor: "is_active",
  },
  {
    Header: "Action",
    accessor: "action",
  },
]

export const goalColumns = [
  {
    Header: "Sphere",
    accessor: "sphereTitle",
  },
  {
    Header: "Challenge",
    accessor: "challengeDescription",
  },
  {
    Header: "Goal Title",
    accessor: "goalTitle",
  },
  {
    Header: "Goal Intro",
    accessor: "goalDescription",
  },
  {
    Header: "Goal Deadline",
    accessor: "goalDate",
  },
  {
    Header: "Task Title",
    accessor: "tasktitle",
  },
  {
    Header: "Task Intro",
    accessor: "taskDescription",
  },
  {
    Header: "Task Deadline",
    accessor: "taskDeadline",
  },
  {
    Header: "Task Status",
    accessor: "taskIsMarkComplete",
  },
]
