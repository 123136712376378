import {  takeEvery, all, fork } from "redux-saga/effects";
import { GET_CHARTS_DATA } from "./actionTypes";

function* getChartsData() {
    return {}
}

export function* watchGetChartsData() {
    yield takeEvery(GET_CHARTS_DATA, getChartsData);
}

function* dashboardSaga() {
    yield all([fork(watchGetChartsData)]);
}

export default dashboardSaga;
