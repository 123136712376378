import React from "react"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Badge } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  ArticleCategoryListApi,
  ArticleListApi,
  BuilkArticleDelete,
  deleteArticleById,
  toggleStatusArticle,
} from "../../redux/actions"
import { articleColumns, columns } from "./constant"
import moment from "moment/moment"
import trimLargeString from "helpers/trimLargeString"
import DeleteModal from "pages/Calendar/DeleteModal"

const ArticlesList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let abortController = new AbortController()
  const { articleListing, meta } = useSelector(state => ({
    articleListing: state.article.articleListing,
    meta: state.article.meta,
  }))
  const [datatableValue, setDatatableValue] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedArticle, setSelectedArticle] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteSelectedModal, setDeleteSelectedModal] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [statusModal, setStatusModal] = useState(false)
  useEffect(() => {
    return () => abortController.abort()
  }, [])

  useEffect(() => {
    if (articleListing?.length) {
      let data = []
      for (const item of articleListing) {
        data.push({
          checkbox: (
            <input
              type="checkbox"
              className="form-check-input table-container-checkbox"
              id="customCheck1"
              checked={item.selected}
              onChange={e => {
                if (e.target.checked) {
                  setSelectedRows([...selectedRows, item.id])
                } else {
                  const _rows = selectedRows?.filter((x, i) => x !== item.id)
                  setSelectedRows(_rows)
                }
              }}
            />
          ),
          action: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                className="btn action-btn"
                type="button"
                onClick={() => navigate(`/dashboard/articles/${item.id}`)}
              >
                <i
                  style={{ fontSize: 20 }}
                  className="mdi mdi-eye action-icon color-black"
                />
              </button>
              <button
                className="btn action-btn"
                onClick={() => navigate(`/dashboard/articles/edit/${item.id}`)}
              >
                <i
                  style={{ fontSize: 20 }}
                  className="mdi mdi-pencil action-icon color-green"
                />
              </button>
              <button
                className="btn action-btn"
                onClick={() => toggleDeleteModal(item.id)}
              >
                <i
                  style={{ fontSize: 20 }}
                  className="mdi mdi-delete action-icon color-red"
                />
              </button>
            </div>
          ),
          title: item?.title,
          description: trimLargeString(item?.description, 150),
          category: item?.sphere?.name,
          created_at: moment(item?.created_at).format("DD MMM, YYYY"),
          is_active: item.is_active ? (
            <Badge
              style={{ cursor: "pointer" }}
              onClick={() => toggleStatusModal(item.id)}
              className="badge active-badge"
            >
              Active
            </Badge>
          ) : (
            <Badge
              style={{ cursor: "pointer" }}
              onClick={() => toggleStatusModal(item.id)}
              className="badge inactive-badge"
            >
              Deactive
            </Badge>
          ),
        })
      }
      setDatatableValue(data)
    } else {
      setDatatableValue([])
    }
  }, [articleListing, selectedRows])

  useEffect(() => {
    handleOnCheckAllRows()
  }, [meta.currentPage])

  const toggleDeleteModal = id => {
    if (id) {
      setSelectedArticle(id)
    } else {
      setSelectedArticle(null)
    }
    setDeleteModal(true)
  }
  const toggleStatusModal = id => {
    if (id) {
      setSelectedArticle(id)
    } else {
      setSelectedArticle(null)
    }
    setStatusModal(true)
  }

  const getArticles = React.useCallback((page = 1, limit = 10, search = "") => {
    setLoading(true)
    abortController.abort()
    abortController = new AbortController()
    dispatch(ArticleListApi(page, limit, search, true, abortController.signal))
      .then(response => {
        setTimeout(() => {
          setLoading(false)
        }, 300)
      })
      .catch(e => {
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      })
  }, [])

  const getArticleCategories = () => {
    dispatch(ArticleCategoryListApi())
      .then(response => {})
      .catch(e => {})
  }

  const deleteArticle = () => {
    if (selectedArticle)
      dispatch(deleteArticleById(selectedArticle)).then(val => {
        setDeleteModal(false)
        setSelectedArticle(null)
        getArticles()
      })
  }

  const handleOnCheckAllRows = e => {
    const tableCheckboxes = document.querySelectorAll(
      ".table-container-checkbox"
    )
    if (e?.target?.checked) {
      let result = []
      Array.from(tableCheckboxes).map((item, index) => {
        result.push(articleListing[index].id)
        item.checked = true
      })
      setSelectedRows(result)
    } else {
      Array.from(tableCheckboxes).map(item => {
        item.checked = false
      })
      setSelectedRows([])
    }
  }

  const onDeleteSelected = () => {
    setDeleteSelectedModal(!deleteSelectedModal)
  }

  const confirmDeleteSelected = () => {
    let payload = {
      deleted_id: selectedRows,
    }
    dispatch(BuilkArticleDelete(payload))
      .then(val => {
        handleOnCheckAllRows()
        getArticles()
        setDeleteSelectedModal(!deleteSelectedModal)
      })
      .catch(e => {})
  }
  const toggleStatus = () => {
    if (selectedArticle) {
      let payload = {
        id: selectedArticle,
      }
      dispatch(toggleStatusArticle(payload))
        .then(val => {
          setStatusModal(false)
          setSelectedArticle(null)
          getArticles()
        })
        .finally(() => {
          setStatusModal(false)
          setSelectedArticle(null)
        })
    }
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs />
        <TableContainer
          searchPlaceholder={"Search Articles"}
          columns={articleColumns}
          data={datatableValue}
          isGlobalFilter={true}
          isAddOptions={true}
          customPageSize={10}
          pageManualData={meta}
          setManualPagination={true}
          totalPageCount={meta?.totalPages}
          initialState={{
            pageIndex: meta?.totalPages == 0 ? 0 : meta?.currentPage || 1,
            pageSize: 10,
          }}
          fetchData={getArticles}
          className="custom-header-css"
          isDropdown={true}
          addNewHeading="Add New Article"
          tableHeading="Articles Listing"
          handleAddClicks={() => navigate("/dashboard/articles/add")}
          onCheckAllRows={handleOnCheckAllRows}
          selectedRows={selectedRows}
          onDeleteSelected={onDeleteSelected}
          loading={loading}
        />
      </div>
      <DeleteModal
        show={statusModal}
        onCloseClick={() => setStatusModal(false)}
        onDeleteClick={() => toggleStatus()}
        subHeading={"You want to change the status?"}
        deleteButton={false}
      />
      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        onDeleteClick={() => deleteArticle()}
      />

      <DeleteModal
        show={deleteSelectedModal}
        onCloseClick={() => setDeleteSelectedModal(false)}
        onDeleteClick={() => confirmDeleteSelected()}
      />
    </div>
  )
}

export default ArticlesList
