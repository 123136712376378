import React from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Badge } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { columns } from "./constant.js"
import moment from "moment/moment"
import DeleteModal from "pages/Calendar/DeleteModal"
import {
  BulkUserDelete,
  deleteUserById,
  getAllUserListApi,
  toggleUserStatus,
  toggleUserVerify,
} from "redux/actions/userAction"

const UserList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let abortController = new AbortController()
  const [datatableValue, setDatatableValue] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [statusModal, setStatusModal] = useState(false)
  const [verifyModal, setVerifyModal] = useState(false)
  const [deleteSelectedModal, setDeleteSelectedModal] = useState(false)
  const { userListing, meta } = useSelector(state => ({
    userListing: state.user.userListing,
    meta: state.user.meta,
  }))
  const [loading, setLoading] = useState(true)

  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    return () => abortController.abort()
  }, [])

  useEffect(() => {
    if (userListing?.length) {
      let data = []
      for (const item of userListing) {
        data.push({
          checkbox: (
            <input
              type="checkbox"
              className="form-check-input table-container-checkbox"
              id="customCheck1"
              checked={item.selected}
              onChange={e => {
                if (e.target.checked) {
                  setSelectedRows([...selectedRows, item.id])
                } else {
                  const _rows = selectedRows?.filter((x, i) => x !== item.id)
                  setSelectedRows(_rows)
                }
              }}
            />
          ),
          action: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                className="btn action-btn"
                type="button"
                onClick={() =>
                  navigate(`/dashboard/user-management/view/${item.id}`)
                }
              >
                <i
                  style={{ fontSize: 20 }}
                  className="mdi mdi-eye action-icon color-black"
                />
              </button>
              <button
                className="btn action-btn"
                onClick={() =>
                  navigate(`/dashboard/user-management/edit/${item.id}`)
                }
              >
                <i
                  style={{ fontSize: 20 }}
                  className="mdi mdi-pencil action-icon color-green"
                />
              </button>
              <button
                className="btn action-btn"
                onClick={() => toggleDeleteModal(item.id)}
              >
                <i
                  style={{ fontSize: 20 }}
                  className="mdi mdi-delete action-icon color-red"
                />
              </button>
            </div>
          ),
          name: (
            <div className="d-flex" style={{ alignItems: "center" }}>
              <img
                src={item.image_url}
                style={{ objectFit: "fill" }}
                alt="user"
                onError={e =>
                  (e.target.src = "https://via.placeholder.com/150")
                }
                className="avatar-sm rounded-circle me-2"
              />
              <div className="user-name">{item.name}</div>
            </div>
          ),
          email: item.email,
          phone: item.phone,
          created_at: moment(item.created_at).format("DD MMM, YYYY"),
          token: (
            <Badge className="badge inactive-badge">
              {item.verifytoken ?? "N/A"}
            </Badge>
          ),
          user_verified: item?.email_verified_at ? (
            <Badge className="badge active-badge">Verified</Badge>
          ) : (
            <Badge
              style={{ cursor: "pointer" }}
              onClick={() => userVerifyModal(item.email)}
              className="badge inactive-badge"
            >
              Unverified
            </Badge>
          ),
          is_active: item.is_active ? (
            <Badge
              style={{ cursor: "pointer" }}
              onClick={() => toggleStatusModal(item.email)}
              className="badge active-badge"
            >
              Active
            </Badge>
          ) : (
            <Badge
              style={{ cursor: "pointer" }}
              onClick={() => toggleStatusModal(item.email)}
              className="badge inactive-badge"
            >
              Deactive
            </Badge>
          ),
        })
      }
      setDatatableValue(data)
    } else {
      setDatatableValue([])
    }
  }, [userListing, selectedRows])

  useEffect(() => {
    handleOnCheckAllRows()
  }, [meta.currentPage])

  const toggleDeleteModal = id => {
    if (id) {
      setSelectedUser(id)
    } else {
      setSelectedUser(null)
    }
    setDeleteModal(true)
  }
  const userVerifyModal = email => {
    if (email) {
      setSelectedUser(email)
    } else {
      setSelectedUser(null)
    }
    setVerifyModal(true)
  }
  const toggleStatusModal = email => {
    if (email) {
      setSelectedUser(email)
    } else {
      setSelectedUser(null)
    }
    setStatusModal(true)
  }

  const getUserList = React.useCallback((page = 1, limit = 10, search = "") => {
    setLoading(true)
    abortController.abort()
    abortController = new AbortController()
    dispatch(getAllUserListApi(page, limit, search, abortController.signal))
      .then(response => {
        setTimeout(() => {
          setLoading(false)
        }, 300)
      })
      .catch(e => {
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      })
  }, [])

  const deleteUser = () => {
    if (selectedUser)
      dispatch(deleteUserById(selectedUser))
        .then(val => {
          setDeleteModal(false)
          setSelectedUser(null)
          getUserList()
        })
        .finally(() => {
          setDeleteModal(false)
          setSelectedUser(null)
        })
  }

  const handleOnCheckAllRows = e => {
    const tableCheckboxes = document.querySelectorAll(
      ".table-container-checkbox"
    )
    if (e?.target?.checked) {
      let result = []
      Array.from(tableCheckboxes).map((item, index) => {
        result.push(userListing[index].id)
        item.checked = true
      })
      setSelectedRows(result)
    } else {
      Array.from(tableCheckboxes).map(item => {
        item.checked = false
      })
      setSelectedRows([])
    }
  }

  const toggleDeleteSelectedModal = () => {
    setDeleteSelectedModal(!deleteSelectedModal)
  }

  const onDeleteSelected = () => {
    if (selectedRows.length > 0) {
      toggleDeleteSelectedModal()
    }
  }

  const confirmDeleteSelected = () => {
    let payload = {
      deleted_id: selectedRows,
    }
    dispatch(BulkUserDelete(payload))
      .then(val => {
        handleOnCheckAllRows()
        getUserList()
        setDeleteSelectedModal(false)
      })
      .catch(e => {})
      .finally(() => {
        handleOnCheckAllRows()
        setDeleteSelectedModal(false)
      })
  }
  const toggleStatus = () => {
    if (selectedUser) {
      let payload = {
        email: selectedUser,
      }
      dispatch(toggleUserStatus(payload))
        .then(val => {
          setStatusModal(false)
          setSelectedUser(null)
          getUserList()
        })
        .finally(() => {
          setStatusModal(false)
          setSelectedUser(null)
        })
    }
  }
  const toggleUserVerififcation = () => {
    if (selectedUser) {
      let payload = {
        email: selectedUser,
      }
      dispatch(toggleUserVerify(payload))
        .then(val => {
          setVerifyModal(false)
          setSelectedUser(null)
          getUserList()
        })
        .finally(() => {
          setVerifyModal(false)
          setSelectedUser(null)
        })
    }
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs />
        <TableContainer
          searchPlaceholder={"Search Users"}
          columns={columns}
          data={datatableValue}
          isGlobalFilter={true}
          isAddOptions={true}
          customPageSize={10}
          setManualPagination={true}
          totalPageCount={meta?.totalPages}
          initialState={{
            pageIndex: meta?.totalPages == 0 ? 0 : meta?.currentPage || 1,
            pageSize: 10,
          }}
          fetchData={getUserList}
          className="custom-header-css"
          isDropdown={true}
          addNewHeading="Add New User"
          tableHeading="User Listing"
          handleAddClicks={() => navigate("/dashboard/user-management/add")}
          onCheckAllRows={handleOnCheckAllRows}
          selectedRows={selectedRows}
          onDeleteSelected={onDeleteSelected}
          loading={loading}
        />
      </div>
      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        onDeleteClick={() => deleteUser()}
      />
      <DeleteModal
        show={statusModal}
        onCloseClick={() => setStatusModal(false)}
        onDeleteClick={() => toggleStatus()}
        subHeading={"You want to change the status?"}
        deleteButton={false}
      />
      <DeleteModal
        show={verifyModal}
        onCloseClick={() => setVerifyModal(false)}
        onDeleteClick={() => toggleUserVerififcation()}
        subHeading={"You want to verify the user?"}
        deleteButton={false}
      />
      <DeleteModal
        show={deleteSelectedModal}
        onCloseClick={() => setDeleteSelectedModal(false)}
        onDeleteClick={() => confirmDeleteSelected()}
      />
    </div>
  )
}

export default UserList
