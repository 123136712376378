import { appendQueryParams } from "../../utils/urlUtils"
import fetchUtil from "../../utils/fetchUtil"
import { persistor } from "../store"
import { API_METHOD, API_STATUS, API_URL } from "constants/apiResponse"
import { Token } from "constants/enums"
import {
  LOGIN_FAILED,
  LOGIN_INITIATE,
  LOGIN_SUCCESS,
  SIGNUP_FAILED,
  SIGNUP_INITIATE,
  SIGNUP_SUCCESS,
  SIGN_OUT,
} from "redux/constant/authConstant"

export const LoginApi = body => dispatch => {
  dispatch({ type: LOGIN_INITIATE })
  return fetchUtil({
    url: API_URL.AUTH.LOGIN,
    method: API_METHOD.POST,
    body,
    token: false,
    showSuccessToast: true,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        if (response?.data?.user?.personal?.user_type == 0) {
          localStorage.setItem(Token, response.data.user.token)
          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              user: response.data.user,
              token: response.data.user.token,
            },
          })
          return Promise.resolve(response.data.user)
        } else {
          dispatch({ type: LOGIN_FAILED })
          return Promise.reject(response.message)
        }
      } else {
        dispatch({ type: LOGIN_FAILED })
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      console.log(`((((((((((( --- ERROR IN LOGIN API ---))))))))))) `, err)
      dispatch({ type: LOGIN_FAILED })
      return Promise.reject(err)
    })
}

export const SignUpApi = body => dispatch => {
  dispatch({ type: SIGNUP_INITIATE })

  return fetchUtil({
    url: API_URL.AUTH.SIGNUP,
    method: API_METHOD.POST,
    body,
    token: false,
  })
    .then(response => {
      console.log(
        `******************* RESPONSE SIGNUP API ******************************** `,
        response
      )
      if (response.statusCode == API_STATUS.OK) {
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: { user: response.data.User },
        })
        return Promise.resolve(response.data.User)
      } else {
        dispatch({ type: SIGNUP_FAILED })
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      console.log(`((((((((((( --- ERROR IN SIGNUP API ---))))))))))) `, err)
      dispatch({ type: SIGNUP_FAILED })
      return Promise.reject(err)
    })
}

export const ForgotPasswordApi = body => dispatch => {
  return fetchUtil({
    url: API_URL.AUTH.FORGOT,
    method: API_METHOD.POST,
    body,
    token: false,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      console.log(`((((((((((( --- ERROR IN SIGNUP API ---))))))))))) `, err)
      return Promise.reject(err)
    })
}

export const OTPApi = body => dispatch => {
  return fetchUtil({
    url: API_URL.AUTH.OTP,
    method: API_METHOD.POST,
    body,
    token: false,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      console.log(`((((((((((( --- ERROR IN SIGNUP API ---))))))))))) `, err)
      return Promise.reject(err)
    })
}

export const NewPasswordApi = body => dispatch => {
  return fetchUtil({
    url: API_URL.AUTH.NEW_PASSWORD,
    method: API_METHOD.POST,
    body,
    token: false,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      console.log(`((((((((((( --- ERROR IN SIGNUP API ---))))))))))) `, err)
      return Promise.reject(err)
    })
}

export const signOut = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    persistor
      .purge()
      .then(res => {
        dispatch({ type: SIGN_OUT })
        localStorage.clear()
        sessionStorage.clear()
        return resolve(true)
      })
      .catch(e => {
        reject(e)
      })
  })
}

export const ChangePasswordApi = body => dispatch => {
  return fetchUtil({
    url: API_URL.AUTH.CHANGE_PASSWORD,
    method: API_METHOD.POST,
    body,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      console.log(`((((((((((( --- ERROR IN SIGNUP API ---))))))))))) `, err)
      return Promise.reject(err)
    })
}
