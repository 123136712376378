import React, { useState } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { signOut } from "redux/actions"

const ProfileMenu = props => {
  const { signOut } = props
  const navigate = useNavigate()
  const [menu, setMenu] = useState(false)
  const user = useSelector(state => state.auth.user.personal)

  const handleLogoutFunction = () => {
    signOut()
      .then(() => {
        setMenu(!menu)
        navigate("/login", { replace: true })
      })
      .catch(e => {})
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user.image_url}
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">
            {user.name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link onClick={() => setMenu(!menu)} to="/dashboard/change-password">
            <div style={{ cursor: "pointer" }} className="dropdown-item">
              <i className="bx bx-lock font-size-16 align-middle me-1 text-danger" />
              <span>{props.t("Change Password")}</span>
            </div>
          </Link>
          <div className="dropdown-divider" />
          <div
            style={{ cursor: "pointer" }}
            className="dropdown-item"
            onClick={handleLogoutFunction}
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}
const mapDispatchToProps = {
  signOut,
}

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(withTranslation()(ProfileMenu))
