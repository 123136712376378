import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"

import AppLogo from "../../assets/images/AppLogo.png"
import logoLightPng from "../../assets/images/logo-light.png"
import logoLightSvg from "../../assets/images/logo-light.svg"
import logoDark from "../../assets/images/logo-dark.png"

const Sidebar = props => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <div className="logo logo-light">
            <span className="logo-sm navbar-heading">
              <img src={AppLogo} alt="" height="25" />
            </span>
            <span className="logo-lg navbar-heading">
              <img src={AppLogo} alt="" height="30" />
              <h6>THE 5 SPHERES OF FIT</h6>
            </span>
          </div>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(mapStatetoProps, {})(withTranslation()(Sidebar))
