import React, { useEffect, useRef } from "react"
import SimpleBar from "simplebar-react"
import { Link, useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

const SidebarContent = () => {
  const { t } = useTranslation()
  const ref = useRef()
  const { pathname } = useLocation()
  const pathArray = pathname.split("/")

  useEffect(() => {
    ref.current.recalculate()
  })

  const mainMenu = [
    {
      title: "Dashboard",
      icon: <i className="bx bx-home-circle"></i>,
      link: "/dashboard"
    },
    {
      title: "User Management",
      icon: <i className="bx bxs-user-detail"></i>,
      link: "/dashboard/user-management"
    },
    {
      title: "Articles",
      icon: <i className="bx bx-detail"></i>,
      link: "/dashboard/articles"
    },
    {
      title: "Pages Management",
      icon: <i className="bx bx-task"></i>,
      link: "/dashboard/pages"
    },
    {
      title: "Challenges",
      icon: <i className="bx bxs-flag"></i>,
      link: "/dashboard/challenges"
    },
    {
      title: "How To Use",
      icon: <i className="bx bx-help-circle"></i>,
      link: "/dashboard/how-to-use"
    },
  ]

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{t("Menu")}</li>
            {mainMenu.map((item, index) => {
              const isActive = pathArray[2] === item.link.split("/")[item.link.split("/").length - 1]
              return (
                <li key={index} className={isActive ? "mm-active" : (pathname === "/dashboard" && item.link === "/dashboard") ? "mm-active" : ""}>
                  <Link to={item.link}>
                    {item.icon ? item.icon : <i className="bx bx-book-open"></i>}
                    <span>{t(item.title)}</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}


export default SidebarContent
