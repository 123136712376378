import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router"
import { Card, CardBody, CardTitle, Spinner } from "reactstrap"
import {
  deleteUserById,
  getUserById,
  UserChallengeList,
} from "redux/actions/userAction"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import { combineDeadlineDate, formatedDate, overDueDate } from "utils/dateUtil"
import TableContainer from "components/Common/TableContainer"
import { goalColumns } from "./constant"
import moment from "moment"
import { US_COUNTRY_CODE } from "constants/statesArray"

const UserView = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const [datatableValue, setDatatableValue] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingInformation, setLoadingInformation] = useState(true)
  const [userData, setUserData] = useState()
  const { userChallengeListing, userChallengeListingMeta } = useSelector(
    state => ({
      userChallengeListing: state.user.userChallengeListing,
      userChallengeListingMeta: state.user.userChallengeListingMeta,
    })
  )
  useEffect(() => {
    if (userChallengeListing?.length) {
      let data = []
      for (const item of userChallengeListing) {
        data.push({
          action: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                className="btn action-btn"
                type="button"
                onClick={() => console.log("ao")}
              >
                <i className="mdi mdi-eye action-icon color-black" />
              </button>
            </div>
          ),
          sphereTitle: item.sphere.name,
          challengeDescription: item.challenge.label,
          tasktitle: item.title,
          taskDescription: item.description,
          taskDeadline: formatedDate(item.deadline),
          taskIsMarkComplete: item.is_mark_complete
            ? "Completed"
            : overDueDate(item.deadline),
          goalTitle: item.user_goal.title,
          goalDescription: item.user_goal.description,
          goalDate: formatedDate(item.user_goal.date),
        })
      }
      setDatatableValue(data)
    } else {
      setDatatableValue([])
    }
  }, [userChallengeListing])
  useEffect(() => {
    if (id) {
      getUserData(id)
    }
  }, [id])

  const getUserData = id => {
    setLoadingInformation(true)
    dispatch(getUserById(id))
      .then(value => {
        setUserData(value)
        setLoadingInformation(false)
      })
      .catch(() => {
        setLoadingInformation(false)
      })
  }

  const deleteUser = () => {
    dispatch(deleteUserById(userData.detail.user_id)).then(val => {
      setDeleteModal(false)
      navigate("/dashboard/user-management")
    })
  }
  const toggleDeleteModal = () => {
    setDeleteModal(true)
  }
  const getUserChallengeList = React.useCallback(
    (page = 1, limit = 10, search) => {
      setLoading(true)
      dispatch(UserChallengeList(id, page, limit, search))
        .then(response => {
          setTimeout(() => {
            setLoading(false)
          }, 300)
        })
        .catch(e => {
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        })
    },
    []
  )
  if (loading && loadingInformation) {
    return (
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="User" hidePath={true} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            height: "calc(100vh - 180px)",
          }}
        >
          <Spinner />
        </div>
      </div>
    )
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Breadcrumbs title="User" hidePath={true} />

          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div>
              <button
                className="btn action-btn"
                onClick={() =>
                  navigate(
                    `/dashboard/user-management/edit/${userData.detail.user_id}`
                  )
                }
              >
                <i
                  style={{ fontSize: 20 }}
                  className="mdi mdi-pencil action-icon color-green"
                />
              </button>
            </div>
            <div>
              <button
                className="btn action-btn"
                onClick={() => toggleDeleteModal()}
              >
                <i
                  style={{ fontSize: 20 }}
                  className="mdi mdi-delete action-icon color-red"
                />
              </button>
            </div>
          </div>
        </div>

        <Card>
          <CardBody>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  width={62}
                  height={62}
                  style={{ borderRadius: "100%" }}
                  src={userData?.personal?.image_url}
                />
                <div style={{ marginLeft: 15 }}>
                  <h3
                    style={{ color: "#495057", fontSize: 16, fontWeight: 500 }}
                  >
                    {userData?.personal?.name}
                  </h3>
                  <p
                    style={{ fontSize: 13, color: "#74788D", marginBottom: 0 }}
                  >
                    {formatedDate(userData?.personal?.created_date)}
                  </p>
                </div>
              </div>

              <div>
                <div
                  className={`badge ${
                    userData?.personal?.email_verified_at ? "" : "in"
                  }active-badge`}
                  style={{
                    fontSize: 12,
                    padding: "4px 14px",
                    borderRadius: 10,
                  }}
                >
                  {userData?.personal?.email_verified_at ? (
                    <>
                      Verified
                      <i
                        className="mdi mdi-check-circle"
                        style={{ marginLeft: 5 }}
                      />
                    </>
                  ) : (
                    "Unverified"
                  )}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <CardTitle>Personal Information</CardTitle>

            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderWidth: "1px 0px 1px 0px",
                  borderStyle: "solid",
                  borderColor: "#EFF2F7",
                  padding: "14px 5px",
                }}
              >
                <div
                  style={{
                    color: "#495057",
                    fontSize: 14,
                    fontWeight: 600,
                    width: 200,
                  }}
                >
                  Full Name:{" "}
                </div>
                <div style={{ marginLeft: 15 }}>
                  {" "}
                  {userData?.personal?.name}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderWidth: "0px 0px 1px 0px",
                  borderStyle: "solid",
                  borderColor: "#EFF2F7",
                  padding: "0px 5px 14px",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    color: "#495057",
                    fontSize: 14,
                    fontWeight: 600,
                    width: 200,
                  }}
                >
                  Mobile:{" "}
                </div>
                <div style={{ marginLeft: 15 }}>
                  {" "}
                  {userData?.personal?.phone}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderWidth: "0px 0px 1px 0px",
                  borderStyle: "solid",
                  borderColor: "#EFF2F7",
                  padding: "0px 5px 14px",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    color: "#495057",
                    fontSize: 14,
                    fontWeight: 600,
                    width: 200,
                  }}
                >
                  Email:{" "}
                </div>
                <div style={{ marginLeft: 15 }}>
                  {" "}
                  {userData?.personal?.email}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderWidth: "0px 0px 1px 0px",
                  borderStyle: "solid",
                  borderColor: "#EFF2F7",
                  padding: "0px 5px 14px",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    color: "#495057",
                    fontSize: 14,
                    fontWeight: 600,
                    width: 200,
                  }}
                >
                  Date Of Birth:{" "}
                </div>
                <div style={{ marginLeft: 15 }}> {userData?.personal?.dob}</div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderWidth: "0px 0px 1px 0px",
                  borderStyle: "solid",
                  borderColor: "#EFF2F7",
                  padding: "0px 5px 14px",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    color: "#495057",
                    fontSize: 14,
                    fontWeight: 600,
                    width: 200,
                  }}
                >
                  Gender:{" "}
                </div>
                <div style={{ marginLeft: 15 }}>
                  {" "}
                  {userData?.personal?.gender_obj?.label}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderWidth: "0px 0px 1px 0px",
                  borderStyle: "solid",
                  borderColor: "#EFF2F7",
                  padding: "0px 5px 14px",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    color: "#495057",
                    fontSize: 14,
                    fontWeight: 600,
                    width: 200,
                  }}
                >
                  Country:{" "}
                </div>
                <div style={{ marginLeft: 15 }}>
                  {userData?.detail?.country_obj?.name}
                </div>
              </div>

              {userData?.detail?.country_id == US_COUNTRY_CODE && (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderWidth: "0px 0px 1px 0px",
                      borderStyle: "solid",
                      borderColor: "#EFF2F7",
                      padding: "0px 5px 14px",
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        color: "#495057",
                        fontSize: 14,
                        fontWeight: 600,
                        width: 200,
                      }}
                    >
                      State:{" "}
                    </div>
                    <div style={{ marginLeft: 15 }}>
                      {userData?.detail?.state_obj?.name}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderWidth: "0px 0px 1px 0px",
                      borderStyle: "solid",
                      borderColor: "#EFF2F7",
                      padding: "0px 5px 14px",
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        color: "#495057",
                        fontSize: 14,
                        fontWeight: 600,
                        width: 200,
                      }}
                    >
                      City:{" "}
                    </div>
                    <div style={{ marginLeft: 15 }}>
                      {userData?.detail?.city_name}
                    </div>
                  </div>
                </>
              )}
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <TableContainer
              searchPlaceholder={"Search"}
              columns={goalColumns}
              data={datatableValue}
              isGlobalFilter={true}
              isAddOptions={false}
              customPageSize={10}
              setManualPagination={true}
              totalPageCount={userChallengeListingMeta?.totalPages}
              initialState={{
                pageIndex:
                  userChallengeListingMeta?.totalPages === 0
                    ? 0
                    : userChallengeListingMeta?.currentPage || 1,
                pageSize: 10,
              }}
              fetchData={getUserChallengeList}
              className="custom-header-css"
              isDropdown={true}
              addNewHeading="Add New User"
              tableHeading="Personal Journal"
              loading={loading}
            />
          </CardBody>
        </Card>
      </div>
      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        onDeleteClick={() => deleteUser()}
      />
    </div>
  )
}
export default UserView
