import { appendQueryParams } from "../../utils/urlUtils"
import fetchUtil from "../../utils/fetchUtil"
import { persistor } from "../store"
import { API_METHOD, API_STATUS, API_URL } from "constants/apiResponse"
import { PAGE_LISTING, PAGE_LISTING_META } from "redux/constant/authConstant"

export const PageListApi =
  (page = 1, limit = 10, search = "") =>
  dispatch => {
    return fetchUtil({
      url: appendQueryParams(API_URL.PAGE.LIST, { page, limit, search }),
      method: API_METHOD.GET,
      showSuccessToast: false,
    })
      .then(response => {
        if (response.statusCode == API_STATUS.OK) {
          dispatch({
            type: PAGE_LISTING_META,
            payload: response.data.meta,
          })
          dispatch({ type: PAGE_LISTING, payload: response.data.data })
          return Promise.resolve(response.data.data)
        } else {
          return Promise.reject(response.message)
        }
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }

export const EditPageListApi = id => dispatch => {
  return fetchUtil({
    url: appendQueryParams(API_URL.PAGE.EDIT, { id }),
    method: API_METHOD.GET,
    showSuccessToast: false,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const SavePageApi = body => dispatch => {
  return fetchUtil({
    url: API_URL.PAGE.SAVE,
    method: API_METHOD.POST,
    body,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
