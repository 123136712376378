import fetchUtil from "../../utils/fetchUtil"
import { API_METHOD, API_STATUS, API_URL } from "constants/apiResponse"
import { CONTENT_TYPES } from "constants/appConstants"

export const getHowToUseData = id => dispatch => {
  return fetchUtil({
    url: API_URL.HOW_TO_USE.get,
    method: API_METHOD.GET,
    showSuccessToast: false,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
export const saveHowToUseApi = body => dispatch => {
  return fetchUtil({
    url: API_URL.HOW_TO_USE.save,
    method: API_METHOD.POST,
    body,
    contentType: CONTENT_TYPES.FORM_DATA,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
