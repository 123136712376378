import React, { useEffect } from "react"
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { useClearCache } from "react-clear-cache"
import Login from "pages/Authentication/Login"
import Authmiddleware from "routes/route"
import OTP from "pages/Authentication/OTP"
import NewPassword from "pages/Authentication/NewPassword"
import ForgetPassword from "pages/Authentication/ForgetPassword"
import NoAuth from "components/NoAuth"
import Auth from "components/Auth"
import Layout from "components/Layout"
import ErrorBoundary from "components/ErrorBoundary"
import Dashboard from "pages/Dashboard"
import PageList from "pages/page/PageList"
import EditPage from "pages/page/EditPage"
import ArticlesList from "pages/articles/ArticlesList"
import ArticleCategoryList from "pages/articleCategory/ArticleCategoryList"
import EditCategory from "pages/articleCategory/EditCategory"
import ArticleForm from "pages/articles/ArticleForm"
import ArticleView from "pages/articles/ArticleView"
import UserList from "pages/userManagement/UserList"

import "react-toastify/dist/ReactToastify.css"
import "./assets/scss/theme.scss"
import "./assets/css/custom.css"
import ChangePassword from "pages/changePassword"
import UserForm from "pages/userManagement/UserForm"
import UserView from "pages/userManagement/UserView"
import ChallengeList from "pages/Challenge/ChallengeList"
import ChallengeForm from "pages/Challenge/ChallengeForm"
import HowtoUse from "pages/HowToUse"
import Preview from "pages/HowToUse/preview"

const App = props => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache()
  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage()
    }
  }, [isLatestVersion, emptyCacheStorage])

  const routes = [
    {
      path: "/",
      errorElement: <ErrorBoundary />,
      element: <Navigate to={"/login"} />,
    },
    {
      path: "/login",
      errorElement: <ErrorBoundary />,
      element: (
        <NoAuth>
          <Login {...props} title="Login" />
        </NoAuth>
      ),
    },
    {
      path: "/forget",
      errorElement: <ErrorBoundary />,
      element: (
        <NoAuth>
          <ForgetPassword {...props} title="Forget Password" />
        </NoAuth>
      ),
    },
    {
      path: "/otp",
      errorElement: <ErrorBoundary />,
      element: (
        <NoAuth>
          <OTP {...props} title="OTP Verification" />
        </NoAuth>
      ),
    },
    {
      path: "/new-password",
      errorElement: <ErrorBoundary />,
      element: (
        <NoAuth>
          <NewPassword {...props} title="Set New Password" />
        </NoAuth>
      ),
    },
    {
      path: "/dashboard",
      errorElement: <ErrorBoundary />,
      element: (
        <Auth>
          <Authmiddleware {...props} Layout={Layout} title="Authmiddleware" />
        </Auth>
      ),
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
        {
          path: "/dashboard/pages",
          element: <PageList />,
        },
        {
          path: "/dashboard/pages/:id",
          element: <EditPage />,
        },
        {
          path: "/dashboard/articles",
          element: <ArticlesList />,
        },
        {
          path: "/dashboard/articles/:id",
          element: <ArticleView />,
        },
        {
          path: "/dashboard/articles/add",
          element: <ArticleForm />,
        },
        {
          path: "/dashboard/articles/edit/:id",
          element: <ArticleForm />,
        },
        {
          path: "/dashboard/article-categories",
          element: <ArticleCategoryList />,
        },
        {
          path: "/dashboard/article-categories/:id",
          element: <EditCategory />,
        },
        {
          path: "/dashboard/user-management",
          element: <UserList />,
        },
        {
          path: "/dashboard/user-management/add",
          element: <UserForm />,
        },
        {
          path: "/dashboard/user-management/edit/:id",
          element: <UserForm />,
        },
        {
          path: "/dashboard/user-management/view/:id",
          element: <UserView />,
        },
        {
          path: "/dashboard/change-password",
          element: <ChangePassword />,
        },
        {
          path: "/dashboard/challenges",
          element: <ChallengeList />,
        },
        {
          path: "/dashboard/challenges/add",
          element: <ChallengeForm />,
        },
        {
          path: "/dashboard/challenges/edit/:id",
          element: <ChallengeForm />,
        },
        {
          path: "/dashboard/how-to-use",
          element: <HowtoUse />,
        },
        {
          path: "/dashboard/how-to-use/preview",
          element: <Preview />,
        },
      ],
    },
  ]

  const router = createBrowserRouter(routes)

  return (
    <div id="app-container">
      <RouterProvider router={router} />
      <ToastContainer containerId={"global-toast"} />
    </div>
  )
}

export default App
