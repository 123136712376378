import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Spinner,
} from "reactstrap"
import { NotificationListApi } from "redux/actions/notificationActions"
import SimpleBar from "simplebar-react"

const NotificationDropdown = () => {
  const [menu, setMenu] = useState(false)
  const [loading, setLoading] = useState(false)
  const { notifications, currentPage } = useSelector(
    state => state.notifications
  )
  const dispatch = useDispatch()

  const getNotifications = React.useCallback((page = 1, limit = 5, refresh) => {
    setLoading(true)
    dispatch(NotificationListApi(page, limit, true, refresh))
      .then(response => {})
      .catch(e => {})
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      getNotifications(1, 5, true)
    }, 120000)
    getNotifications(1)
    return () => clearInterval(interval)
  }, [])

  const handleViewMore = () => {
    getNotifications(currentPage + 1)
  }
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell" />
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> Notifications </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notifications?.map((item, index) => {
              return (
                <Link
                  key={index}
                  to={
                    item.target_screen === "contact_us"
                      ? "#"
                      : `/dashboard/user-management/view/${item?.data?.detail?.user_id}`
                  }
                  className="text-reset notification-item"
                >
                  <div className="d-flex">
                    <div
                      className="avatar-xs me-3"
                      style={{ width: 32, height: 30 }}
                    >
                      <span
                        className="avatar-title bg-primary rounded-circle font-size-16"
                        style={{ width: 32, height: 32 }}
                      >
                        <i
                          className={`bx bx-${
                            item.target_screen === "contact_us"
                              ? "message-square-dots"
                              : "user-plus"
                          }`}
                        />
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6
                        className="mt-0 mb-1"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item.title}
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1" style={{ wordBreak: "break-word" }}>
                          {item.body}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              )
            })}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <div
              className="btn btn-sm curson-pointer btn-link font-size-14 text-center"
              onClick={() => handleViewMore()}
            >
              {loading ? (
                <Spinner size="sm" color="primary" />
              ) : (
                <>
                  <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
                  <span key="t-view-more">Load More</span>
                </>
              )}
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default NotificationDropdown
