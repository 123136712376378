import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import moment from "moment"
import { useDispatch } from "react-redux"
import { getHowToUseData } from "redux/actions/howToUseAction"

const Preview = () => {
  const dispatch = useDispatch()
  const [howToUseData, setHowToUseData] = useState({})
  const [extras, setExtras] = useState([])

  useEffect(() => {
    dispatch(getHowToUseData())
      .then(val => {
        setHowToUseData({ ...val })
        setExtras(val?.extras)
      })
      .catch(e => {})
  }, [])

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="View How to use" />

        <Card>
          <CardBody>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="mb-3"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 10,
                }}
              >
                {moment(howToUseData?.updated_at).isValid() && (
                  <>
                    <i className="mdi mdi-calendar action-icon color-black" />
                    <p style={{ marginLeft: 5, marginBottom: 0 }}>
                      Last Updated:
                      {moment(howToUseData?.updated_at).format(
                        "DD MMM, YYYY HH:mm A"
                      )}
                    </p>
                  </>
                )}
              </div>
            </div>

            <div className="my-2">
              <Row>
                <Col md={6}>
                  <img
                    style={{
                      height: "315px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={howToUseData?.image_url}
                    className="img-fluid"
                  />
                </Col>
                <Col md={6}>
                  <video
                    width="560"
                    height="315"
                    src={howToUseData?.video_url}
                    title="Video player"
                    type="video/mp4"
                    controls={true}
                  ></video>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle>{howToUseData?.title}</CardTitle>
            <Row>
              <Col md={12}>
                <ul>
                  {extras?.length &&
                    extras.map((val, index) => {
                      return <li key={index}>{val}</li>
                    })}
                </ul>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default Preview
