import Config from "config"
import { handleFetchError } from "./handleFetchError"
import { getToken } from "./tokenUtils"
import { CONTENT_TYPES } from "constants/appConstants"

export default ({
  url = ``,
  method = "GET",
  body,
  queryParams = "",
  token = true,
  showSuccessToast = true,
  contentType = CONTENT_TYPES.JSON,
  signal = null,
}) => {
  return new Promise((resolve, reject) => {
    let headers = {}
    if (contentType === CONTENT_TYPES.JSON) {
      headers = { ...headers, "Content-Type": contentType }
    }
    if (token) {
      let token1 = getToken()
      headers = { ...headers, Authorization: `Bearer ${token1}` }
    }

    const reqBody =
      contentType === CONTENT_TYPES.JSON ? JSON.stringify(body) : body

    fetch(`${Config.env().API_URL}${url}${queryParams}`, {
      method,
      headers,
      body: reqBody,
      signal,
    })
      .then(res => {
        let response = handleFetchError(res, showSuccessToast)
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}
