import React from "react"
import { Button, Spinner } from "reactstrap"

const FormButton = ({ children, isLoading, style, disabled, ...rest }) => {
  return (
    <Button
      {...rest}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
      disabled={disabled}
    >
      {isLoading && (
        <Spinner size="sm" color="light" style={{ marginRight: 10 }} />
      )}
      {children}
    </Button>
  )
}

export default FormButton
