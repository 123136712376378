import Pages404 from "pages/Utility/pages-404"
import Pages500 from "pages/Utility/pages-500"
import React from "react"
import { useRouteError } from "react-router"
import { Link } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"

const ErrorBoundary = () => {
  let ErrorResponse = useRouteError()
  console.log(ErrorResponse.status, "errorerrorerror")

  switch (ErrorResponse.status) {
    case 404:
      return <Pages404 />
    case 400:
      return <Pages500 />
    default:
      return (
        <React.Fragment>
          <div className="account-pages my-5 pt-5">
            <Container>
              <Row>
                <Col lg="12">
                  <div className="text-center mb-5">
                    <h1 className="display-2 font-weight-medium">
                      {ErrorResponse.status}
                    </h1>
                    <h4 className="text-uppercase">
                      {ErrorResponse.statusText}
                    </h4>
                    <div className="mt-5 text-center">
                      <Link className="btn btn-primary " to="/dashboard">
                        Back to Dashboard
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="8" xl="6">
                  <div>
                    <img src={error} alt="" className="img-fluid" />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
      )
  }
}

export default ErrorBoundary
