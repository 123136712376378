import { APP_INFO } from "constants/appConstants"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router"

const Auth = ({ children }) => {

  const location = useLocation()

  //token and isAuthenticated from redux
  const { isAuthenticated, token } = useSelector(state => state.auth)

  useEffect(() => {
    const title = location.pathname.split("/")[location.pathname.split("/").length - 1]
    document.title = title.charAt(0).toUpperCase() + title.slice(1) + " | " + APP_INFO.NAME
  }, [location])

  if (isAuthenticated && token) {
    return children
  } else {
    return <Navigate to={"/login"} replace />
  }
}
export default Auth
