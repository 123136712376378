import React from "react"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { PageListApi } from "../../redux/actions"
import { columns } from "./constant"
import moment from "moment/moment"

const PageList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let abortController = new AbortController()
  const [loading, setLoading] = useState(true)
  const [datatableValue, setDatatableValue] = useState([])

  const { pageListing, meta } = useSelector(state => ({
    pageListing: state.page.pageListing,
    meta: state.page.meta,
  }))
  useEffect(() => {
    return () => abortController.abort()
  }, [])
  useEffect(() => {
    if (pageListing?.length) {
      let data = []
      for (const dataValues of pageListing) {
        data.push({
          checkbox: (
            <input
              type="checkbox"
              className="form-check-input"
              id="customCheck1"
            />
          ),
          action: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                className="btn action-btn"
                onClick={() => navigate(`/dashboard/pages/${dataValues.id}`)}
              >
                <i
                  style={{ fontSize: 20 }}
                  className="mdi mdi-pencil action-icon color-green"
                />
              </button>
              <a
                href={dataValues?.page_url}
                target="_blank"
                rel="noreferrer"
                style={{ color: "black", fontSize: 15 }}
              >
                <i style={{ fontSize: 20 }} className="bx bx-link-external"></i>
              </a>
            </div>
          ),
          name: dataValues.name,
          slug: dataValues.slug,
          created_at: moment(dataValues.created_at).format("DD MMM, YYYY"),
        })
      }
      setDatatableValue(data)
    } else {
      setDatatableValue([])
    }
  }, [pageListing])

  const getPages = React.useCallback((page = 1, limit = 10, search = "") => {
    setLoading(true)
    abortController.abort()
    abortController = new AbortController()
    dispatch(PageListApi(page, limit, search, abortController.signal))
      .then(response => {
        setTimeout(() => {
          setLoading(false)
        }, 300)
      })
      .catch(e => {
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      })
  }, [])
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs />
        <TableContainer
          searchPlaceholder={"Search Pages"}
          columns={columns}
          data={datatableValue}
          isGlobalFilter={true}
          isAddOptions={false}
          customPageSize={10}
          pageManualData={meta}
          setManualPagination={true}
          totalPageCount={meta?.totalPages}
          initialState={{
            pageIndex: meta?.totalPages == 0 ? 0 : meta?.currentPage || 1,
            pageSize: meta?.itemsPerPage || 10,
          }}
          fetchData={getPages}
          className="custom-header-css"
          isDropdown={true}
          tableHeading="Pages Listing"
          loading={loading}
        />
      </div>
    </div>
  )
}

export default PageList
