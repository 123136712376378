export const columns = [
  // {
  //     Header:"Checkbox",
  //     accessor:"checkbox"
  // },
  {
    Header: "Category Name",
    accessor: "sphere_name",
    style: { fontWeight: "bold" },
  },
  {
    Header: "Challenge",
    accessor: "label",
  },

  {
    Header: "Action",
    accessor: "action",
  },
]
