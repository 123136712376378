import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Card, CardBody, Badge } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"

import { getChartsData as onGetChartsData } from "../../store/actions"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableContainer from "components/Common/TableContainer"
import { columns } from "pages/articles/constant"
import {
  ArticleListApi,
  deleteArticleById,
  toggleStatusArticle,
} from "redux/actions"
import trimLargeString from "helpers/trimLargeString"
import moment from "moment"
import { getDashboardApi } from "redux/actions/userAction"
import DeleteModal from "pages/Calendar/DeleteModal"

const Dashboard = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [datatableValue, setDatatableValue] = useState([])
  const [selectedArticle, setSelectedArticle] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [statusModal, setStatusModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))
  const [reports, setReports] = useState([])

  useEffect(() => {
    getDashboardData()
    getArticles()
  }, [])

  const [periodData, setPeriodData] = useState([])

  useEffect(() => {
    if (chartsData) setPeriodData(chartsData)
  }, [chartsData])

  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])
  const toggleDeleteModal = id => {
    if (id) {
      setSelectedArticle(id)
    } else {
      setSelectedArticle(null)
    }
    setDeleteModal(true)
  }
  const toggleStatusModal = id => {
    if (id) {
      setSelectedArticle(id)
    } else {
      setSelectedArticle(null)
    }
    setStatusModal(true)
  }
  const deleteArticle = () => {
    if (selectedArticle)
      dispatch(deleteArticleById(selectedArticle)).then(val => {
        setDeleteModal(false)
        setSelectedArticle(null)
        getArticles()
      })
  }

  const getDashboardData = () => {
    dispatch(getDashboardApi()).then(val => {
      let data = [
        {
          title: "Users",
          iconClass: "bxs-user-detail",
          description: val.user_count,
        },
        {
          title: "Articles",
          iconClass: "bx-news",
          description: val?.article_count,
        },
        {
          title: "Contact Us",
          iconClass: "bxs-phone",
          description: val?.contact_count,
        },
      ]
      setReports(data)
    })
  }
  const getArticles = (
    page = 1,
    limit = 10,
    search = "",
    isDispatching = false
  ) => {
    setLoading(true)
    dispatch(ArticleListApi(page, limit, search, isDispatching))
      .then(response => {
        let data = []
        for (const item of response) {
          data.push({
            checkbox: (
              <input
                type="checkbox"
                className="form-check-input"
                id="customCheck1"
              />
            ),
            action: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <button
                  className="btn action-btn"
                  type="button"
                  onClick={() => navigate(`/dashboard/articles/${item.id}`)}
                >
                  <i
                    style={{ fontSize: 20 }}
                    className="mdi mdi-eye action-icon color-black"
                  />
                </button>
                <button
                  className="btn action-btn"
                  onClick={() =>
                    navigate(`/dashboard/articles/edit/${item.id}`)
                  }
                >
                  <i
                    style={{ fontSize: 20 }}
                    className="mdi mdi-pencil action-icon color-green"
                  />
                </button>
                <button
                  className="btn action-btn"
                  onClick={() => toggleDeleteModal(item.id)}
                >
                  <i
                    style={{ fontSize: 20 }}
                    className="mdi mdi-delete action-icon color-red"
                  />
                </button>
              </div>
            ),
            title: item.title,
            description: trimLargeString(item.description, 50),
            category: item.sphere.name,
            created_at: moment(item.created_at).format("DD MMM, YYYY"),
            is_active: item.is_active ? (
              <Badge
                style={{ cursor: "pointer" }}
                onClick={() => toggleStatusModal(item.id)}
                className="badge active-badge"
              >
                Active
              </Badge>
            ) : (
              <Badge
                style={{ cursor: "pointer" }}
                onClick={() => toggleStatusModal(item.id)}
                className="badge inactive-badge"
              >
                Deactive
              </Badge>
            ),
          })
        }
        setDatatableValue(data)
      })
      .catch(e => {})
      .finally(() => {
        setLoading(false)
      })
  }
  const toggleStatus = () => {
    if (selectedArticle) {
      let payload = {
        id: selectedArticle,
      }
      dispatch(toggleStatusArticle(payload))
        .then(val => {
          setStatusModal(false)
          setSelectedArticle(null)
          getArticles()
        })
        .finally(() => {
          setStatusModal(false)
          setSelectedArticle(null)
        })
    }
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs />
          <Row>
            <Col xl="12">
              <Row>
                {reports.map((report, key) => (
                  <Col md="4" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody className="dashboard-card-wrapper">
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.description}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon dasboard-card">
                            <span className="avatar-title rounded-circle bg-dark">
                              <i
                                style={{ fontSize: 32 }}
                                className={"bx " + report.iconClass}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

          <Card className="mini-stats-wid">
            <CardBody
              className="dashboard-card-wrapper"
              style={{ padding: "25px 15px" }}
            >
              <TableContainer
                searchPlaceholder={"Search Articles"}
                columns={columns}
                data={datatableValue}
                isGlobalFilter={false}
                showLimitTab={false}
                showPagination={false}
                customPageSize={10}
                className="custom-header-css"
                tableHeading="Recent Articles"
                loading={loading}
              />
              <DeleteModal
                show={statusModal}
                onCloseClick={() => setStatusModal(false)}
                onDeleteClick={() => toggleStatus()}
                subHeading={"You want to change the status?"}
                deleteButton={false}
              />
              <DeleteModal
                show={deleteModal}
                onCloseClick={() => setDeleteModal(false)}
                onDeleteClick={() => deleteArticle()}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
