export const statesArray = [
  {
    id: "105",
    country_id: "240",
    label: "Alabama",
  },
  {
    id: "111",
    country_id: "240",
    label: "Alaska",
  },
  {
    id: "218",
    country_id: "240",
    label: "Arizona",
  },
  {
    id: "219",
    country_id: "240",
    label: "Arkansas",
  },
  {
    id: "585",
    country_id: "240",
    label: "California",
  },
  {
    id: "790",
    country_id: "240",
    label: "Colorado",
  },
  {
    id: "800",
    country_id: "240",
    label: "Connecticut",
  },
  {
    id: "877",
    country_id: "240",
    label: "Delaware",
  },
  {
    id: "1079",
    country_id: "240",
    label: "Florida",
  },
  {
    id: "1132",
    country_id: "240",
    label: "Georgia",
  },
  {
    id: "1299",
    country_id: "240",
    label: "Hawaii",
  },
  {
    id: "1367",
    country_id: "240",
    label: "Idaho",
  },
  {
    id: "1400",
    country_id: "240",
    label: "Illinois",
  },
  {
    id: "1416",
    country_id: "240",
    label: "Indiana",
  },
  {
    id: "1423",
    country_id: "240",
    label: "Iowa",
  },
  {
    id: "1560",
    country_id: "240",
    label: "Kansas",
  },
  {
    id: "1606",
    country_id: "240",
    label: "Kentucky",
  },
  {
    id: "1903",
    country_id: "240",
    label: "Louisiana",
  },
  {
    id: "1958",
    country_id: "240",
    label: "Maine",
  },
  {
    id: "2034",
    country_id: "240",
    label: "Maryland",
  },
  {
    id: "2042",
    country_id: "240",
    label: "Massachusetts",
  },
  {
    id: "2098",
    country_id: "240",
    label: "Michigan",
  },
  {
    id: "2117",
    country_id: "240",
    label: "Minnesota",
  },
  {
    id: "2130",
    country_id: "240",
    label: "Mississippi",
  },
  {
    id: "2131",
    country_id: "240",
    label: "Missouri",
  },
  {
    id: "2159",
    country_id: "240",
    label: "Montana",
  },
  {
    id: "2269",
    country_id: "240",
    label: "Nebraska",
  },
  {
    id: "2280",
    country_id: "240",
    label: "Nevada",
  },
  {
    id: "2283",
    country_id: "240",
    label: "New Hampshire",
  },
  {
    id: "2285",
    country_id: "240",
    label: "New Jersey",
  },
  {
    id: "2286",
    country_id: "240",
    label: "New Mexico",
  },
  {
    id: "2290",
    country_id: "240",
    label: "New York",
  },
  {
    id: "2351",
    country_id: "240",
    label: "North Carolina",
  },
  {
    id: "2354",
    country_id: "240",
    label: "North Dakota",
  },
  {
    id: "2452",
    country_id: "240",
    label: "Ohio",
  },
  {
    id: "2458",
    country_id: "240",
    label: "Oklahoma",
  },
  {
    id: "2489",
    country_id: "240",
    label: "Oregon",
  },
  {
    id: "2586",
    country_id: "240",
    label: "Pennsylvania",
  },
  {
    id: "2796",
    country_id: "240",
    label: "Rhode Island",
  },
  {
    id: "3234",
    country_id: "240",
    label: "South Carolina",
  },
  {
    id: "3235",
    country_id: "240",
    label: "South Dakota",
  },
  {
    id: "3430",
    country_id: "240",
    label: "Tennessee",
  },
  {
    id: "3437",
    country_id: "240",
    label: "Texas",
  },
  {
    id: "3586",
    country_id: "240",
    label: "Utah",
  },
  {
    id: "3655",
    country_id: "240",
    label: "Vermont",
  },
  {
    id: "3688",
    country_id: "240",
    label: "Virginia",
  },
  {
    id: "3727",
    country_id: "240",
    label: "Washington",
  },
  {
    id: "3728",
    country_id: "240",
    label: "Washington, D.C.",
  },
  {
    id: "3752",
    country_id: "240",
    label: "West Virginia",
  },
  {
    id: "3776",
    country_id: "240",
    label: "Wisconsin",
  },
  {
    id: "3783",
    country_id: "240",
    label: "Wyoming",
  },
]

export const countryArray = [
  {
    id: "1",
    name: "Afghanistan",
    phone_code: "93",
    label: "Afghanistan",
  },
  {
    id: "2",
    name: "Aland Islands",
    phone_code: "358\n",
    label: "Aland Islands",
  },
  {
    id: "3",
    name: "Albania",
    phone_code: "355",
    label: "Albania",
  },
  {
    id: "4",
    name: "Algeria",
    phone_code: "213",
    label: "Algeria",
  },
  {
    id: "5",
    name: "American Samoa",
    phone_code: "1684",
    label: "American Samoa",
  },
  {
    id: "6",
    name: "Andorra",
    phone_code: "376",
    label: "Andorra",
  },
  {
    id: "7",
    name: "Angola",
    phone_code: "244",
    label: "Angola",
  },
  {
    id: "8",
    name: "Anguilla",
    phone_code: "1264",
    label: "Anguilla",
  },
  {
    id: "9",
    name: "Antarctica",
    phone_code: "0",
    label: "Antarctica",
  },
  {
    id: "10",
    name: "Antigua and Barbuda",
    phone_code: "1268",
    label: "Antigua and Barbuda",
  },
  {
    id: "11",
    name: "Argentina",
    phone_code: "54",
    label: "Argentina",
  },
  {
    id: "12",
    name: "Armenia",
    phone_code: "374",
    label: "Armenia",
  },
  {
    id: "13",
    name: "Aruba",
    phone_code: "297",
    label: "Aruba",
  },
  {
    id: "14",
    name: "Australia",
    phone_code: "61",
    label: "Australia",
  },
  {
    id: "15",
    name: "Austria",
    phone_code: "43",
    label: "Austria",
  },
  {
    id: "16",
    name: "Azerbaijan",
    phone_code: "994",
    label: "Azerbaijan",
  },
  {
    id: "17",
    name: "Bahamas",
    phone_code: null,
    label: "Bahamas",
  },
  {
    id: "18",
    name: "Bahrain",
    phone_code: "973",
    label: "Bahrain",
  },
  {
    id: "19",
    name: "Bangladesh",
    phone_code: "880",
    label: "Bangladesh",
  },
  {
    id: "20",
    name: "Barbados",
    phone_code: "1246",
    label: "Barbados",
  },
  {
    id: "21",
    name: "Belarus",
    phone_code: "375",
    label: "Belarus",
  },
  {
    id: "22",
    name: "Belgium",
    phone_code: "32",
    label: "Belgium",
  },
  {
    id: "23",
    name: "Belize",
    phone_code: "501",
    label: "Belize",
  },
  {
    id: "24",
    name: "Benin",
    phone_code: "229",
    label: "Benin",
  },
  {
    id: "25",
    name: "Bermuda",
    phone_code: "1441",
    label: "Bermuda",
  },
  {
    id: "26",
    name: "Bhutan",
    phone_code: "975",
    label: "Bhutan",
  },
  {
    id: "27",
    name: "Bolivia",
    phone_code: "591",
    label: "Bolivia",
  },
  {
    id: "28",
    name: "Bonaire, Saint Eustatius and Saba ",
    phone_code: null,
    label: "Bonaire, Saint Eustatius and Saba ",
  },
  {
    id: "29",
    name: "Bosnia and Herzegovina",
    phone_code: "387",
    label: "Bosnia and Herzegovina",
  },
  {
    id: "30",
    name: "Botswana",
    phone_code: "267",
    label: "Botswana",
  },
  {
    id: "31",
    name: "Bouvet Island",
    phone_code: "0",
    label: "Bouvet Island",
  },
  {
    id: "32",
    name: "Brazil",
    phone_code: "55",
    label: "Brazil",
  },
  {
    id: "33",
    name: "British Indian Ocean Territory",
    phone_code: "246",
    label: "British Indian Ocean Territory",
  },
  {
    id: "34",
    name: "British Virgin Islands",
    phone_code: null,
    label: "British Virgin Islands",
  },
  {
    id: "35",
    name: "Brunei",
    phone_code: "673",
    label: "Brunei",
  },
  {
    id: "36",
    name: "Bulgaria",
    phone_code: "359",
    label: "Bulgaria",
  },
  {
    id: "37",
    name: "Burkina Faso",
    phone_code: "226",
    label: "Burkina Faso",
  },
  {
    id: "38",
    name: "Burundi",
    phone_code: "257",
    label: "Burundi",
  },
  {
    id: "39",
    name: "Cambodia",
    phone_code: "855",
    label: "Cambodia",
  },
  {
    id: "40",
    name: "Cameroon",
    phone_code: "237",
    label: "Cameroon",
  },
  {
    id: "41",
    name: "Canada",
    phone_code: "1",
    label: "Canada",
  },
  {
    id: "42",
    name: "Cape Verde",
    phone_code: "238",
    label: "Cape Verde",
  },
  {
    id: "43",
    name: "Cayman Islands",
    phone_code: "1345",
    label: "Cayman Islands",
  },
  {
    id: "44",
    name: "Central African Republic",
    phone_code: "236",
    label: "Central African Republic",
  },
  {
    id: "45",
    name: "Chad",
    phone_code: "235",
    label: "Chad",
  },
  {
    id: "46",
    name: "Chile",
    phone_code: "56",
    label: "Chile",
  },
  {
    id: "47",
    name: "China",
    phone_code: "86",
    label: "China",
  },
  {
    id: "48",
    name: "Christmas Island",
    phone_code: "61",
    label: "Christmas Island",
  },
  {
    id: "49",
    name: "Cocos Islands",
    phone_code: null,
    label: "Cocos Islands",
  },
  {
    id: "50",
    name: "Colombia",
    phone_code: "57",
    label: "Colombia",
  },
  {
    id: "51",
    name: "Comoros",
    phone_code: "269",
    label: "Comoros",
  },
  {
    id: "52",
    name: "Cook Islands",
    phone_code: "682",
    label: "Cook Islands",
  },
  {
    id: "53",
    name: "Costa Rica",
    phone_code: "506",
    label: "Costa Rica",
  },
  {
    id: "54",
    name: "Croatia",
    phone_code: null,
    label: "Croatia",
  },
  {
    id: "55",
    name: "Cuba",
    phone_code: "53",
    label: "Cuba",
  },
  {
    id: "56",
    name: "Curacao",
    phone_code: null,
    label: "Curacao",
  },
  {
    id: "57",
    name: "Cyprus",
    phone_code: "357",
    label: "Cyprus",
  },
  {
    id: "58",
    name: "Czechia",
    phone_code: null,
    label: "Czechia",
  },
  {
    id: "59",
    name: "Democratic Republic of the Congo",
    phone_code: null,
    label: "Democratic Republic of the Congo",
  },
  {
    id: "60",
    name: "Denmark",
    phone_code: "45",
    label: "Denmark",
  },
  {
    id: "61",
    name: "Djibouti",
    phone_code: "253",
    label: "Djibouti",
  },
  {
    id: "62",
    name: "Dominica",
    phone_code: "1767",
    label: "Dominica",
  },
  {
    id: "63",
    name: "Dominican Republic",
    phone_code: "1809",
    label: "Dominican Republic",
  },
  {
    id: "64",
    name: "East Timor",
    phone_code: "670",
    label: "East Timor",
  },
  {
    id: "65",
    name: "Ecuador",
    phone_code: "593",
    label: "Ecuador",
  },
  {
    id: "66",
    name: "Egypt",
    phone_code: "20",
    label: "Egypt",
  },
  {
    id: "67",
    name: "El Salvador",
    phone_code: "503",
    label: "El Salvador",
  },
  {
    id: "68",
    name: "Equatorial Guinea",
    phone_code: "240",
    label: "Equatorial Guinea",
  },
  {
    id: "69",
    name: "Eritrea",
    phone_code: "291",
    label: "Eritrea",
  },
  {
    id: "70",
    name: "Estonia",
    phone_code: "372",
    label: "Estonia",
  },
  {
    id: "71",
    name: "Ethiopia",
    phone_code: "251",
    label: "Ethiopia",
  },
  {
    id: "72",
    name: "Falkland Islands",
    phone_code: "500",
    label: "Falkland Islands",
  },
  {
    id: "73",
    name: "Faroe Islands",
    phone_code: "298",
    label: "Faroe Islands",
  },
  {
    id: "74",
    name: "Fiji",
    phone_code: null,
    label: "Fiji",
  },
  {
    id: "75",
    name: "Finland",
    phone_code: "358",
    label: "Finland",
  },
  {
    id: "76",
    name: "France",
    phone_code: "33",
    label: "France",
  },
  {
    id: "77",
    name: "French Guiana",
    phone_code: "594",
    label: "French Guiana",
  },
  {
    id: "78",
    name: "French Polynesia",
    phone_code: "689",
    label: "French Polynesia",
  },
  {
    id: "79",
    name: "French Southern Territories",
    phone_code: "0",
    label: "French Southern Territories",
  },
  {
    id: "80",
    name: "Gabon",
    phone_code: "241",
    label: "Gabon",
  },
  {
    id: "81",
    name: "Gambia",
    phone_code: null,
    label: "Gambia",
  },
  {
    id: "82",
    name: "Georgia",
    phone_code: "995",
    label: "Georgia",
  },
  {
    id: "83",
    name: "Germany",
    phone_code: "49",
    label: "Germany",
  },
  {
    id: "84",
    name: "Ghana",
    phone_code: "233",
    label: "Ghana",
  },
  {
    id: "85",
    name: "Gibraltar",
    phone_code: "350",
    label: "Gibraltar",
  },
  {
    id: "86",
    name: "Greece",
    phone_code: "30",
    label: "Greece",
  },
  {
    id: "87",
    name: "Greenland",
    phone_code: "299",
    label: "Greenland",
  },
  {
    id: "88",
    name: "Grenada",
    phone_code: "1473",
    label: "Grenada",
  },
  {
    id: "89",
    name: "Guadeloupe",
    phone_code: "590",
    label: "Guadeloupe",
  },
  {
    id: "90",
    name: "Guam",
    phone_code: "1671",
    label: "Guam",
  },
  {
    id: "91",
    name: "Guatemala",
    phone_code: "502",
    label: "Guatemala",
  },
  {
    id: "92",
    name: "Guernsey",
    phone_code: null,
    label: "Guernsey",
  },
  {
    id: "93",
    name: "Guinea",
    phone_code: "224",
    label: "Guinea",
  },
  {
    id: "94",
    name: "Guinea-Bissau",
    phone_code: "245",
    label: "Guinea-Bissau",
  },
  {
    id: "95",
    name: "Guyana",
    phone_code: "592",
    label: "Guyana",
  },
  {
    id: "96",
    name: "Haiti",
    phone_code: "509",
    label: "Haiti",
  },
  {
    id: "97",
    name: "Heard Island and McDonald Islands",
    phone_code: null,
    label: "Heard Island and McDonald Islands",
  },
  {
    id: "98",
    name: "Honduras",
    phone_code: "504",
    label: "Honduras",
  },
  {
    id: "99",
    name: "Hong Kong",
    phone_code: null,
    label: "Hong Kong",
  },
  {
    id: "100",
    name: "Hungary",
    phone_code: "36",
    label: "Hungary",
  },
  {
    id: "101",
    name: "Iceland",
    phone_code: "354",
    label: "Iceland",
  },
  {
    id: "102",
    name: "India",
    phone_code: "91",
    label: "India",
  },
  {
    id: "103",
    name: "Indonesia",
    phone_code: "62",
    label: "Indonesia",
  },
  {
    id: "104",
    name: "Iran",
    phone_code: "98",
    label: "Iran",
  },
  {
    id: "105",
    name: "Iraq",
    phone_code: "964",
    label: "Iraq",
  },
  {
    id: "106",
    name: "Ireland",
    phone_code: "353",
    label: "Ireland",
  },
  {
    id: "107",
    name: "Isle of Man",
    phone_code: null,
    label: "Isle of Man",
  },
  {
    id: "108",
    name: "Israel",
    phone_code: "972",
    label: "Israel",
  },
  {
    id: "109",
    name: "Italy",
    phone_code: "39",
    label: "Italy",
  },
  {
    id: "110",
    name: "Ivory Coast",
    phone_code: null,
    label: "Ivory Coast",
  },
  {
    id: "111",
    name: "Jamaica",
    phone_code: "1876",
    label: "Jamaica",
  },
  {
    id: "112",
    name: "Japan",
    phone_code: "81",
    label: "Japan",
  },
  {
    id: "113",
    name: "Jersey",
    phone_code: "44",
    label: "Jersey",
  },
  {
    id: "114",
    name: "Jordan",
    phone_code: "962",
    label: "Jordan",
  },
  {
    id: "115",
    name: "Kazakhstan",
    phone_code: "7",
    label: "Kazakhstan",
  },
  {
    id: "116",
    name: "Kenya",
    phone_code: "254",
    label: "Kenya",
  },
  {
    id: "117",
    name: "Kiribati",
    phone_code: "686",
    label: "Kiribati",
  },
  {
    id: "118",
    name: "Kosovo",
    phone_code: null,
    label: "Kosovo",
  },
  {
    id: "119",
    name: "Kuwait",
    phone_code: "965",
    label: "Kuwait",
  },
  {
    id: "120",
    name: "Kyrgyzstan",
    phone_code: "996",
    label: "Kyrgyzstan",
  },
  {
    id: "121",
    name: "Laos",
    phone_code: "856",
    label: "Laos",
  },
  {
    id: "122",
    name: "Latvia",
    phone_code: "371",
    label: "Latvia",
  },
  {
    id: "123",
    name: "Lebanon",
    phone_code: "961",
    label: "Lebanon",
  },
  {
    id: "124",
    name: "Lesotho",
    phone_code: "266",
    label: "Lesotho",
  },
  {
    id: "125",
    name: "Liberia",
    phone_code: "231",
    label: "Liberia",
  },
  {
    id: "126",
    name: "Libya",
    phone_code: "218",
    label: "Libya",
  },
  {
    id: "127",
    name: "Liechtenstein",
    phone_code: "423",
    label: "Liechtenstein",
  },
  {
    id: "128",
    name: "Lithuania",
    phone_code: "370",
    label: "Lithuania",
  },
  {
    id: "129",
    name: "Luxembourg",
    phone_code: "352",
    label: "Luxembourg",
  },
  {
    id: "130",
    name: "Macao",
    phone_code: null,
    label: "Macao",
  },
  {
    id: "131",
    name: "Macedonia",
    phone_code: "389",
    label: "Macedonia",
  },
  {
    id: "132",
    name: "Madagascar",
    phone_code: "261",
    label: "Madagascar",
  },
  {
    id: "133",
    name: "Malawi",
    phone_code: "265",
    label: "Malawi",
  },
  {
    id: "134",
    name: "Malaysia",
    phone_code: "60",
    label: "Malaysia",
  },
  {
    id: "135",
    name: "Maldives",
    phone_code: "960",
    label: "Maldives",
  },
  {
    id: "136",
    name: "Mali",
    phone_code: "223",
    label: "Mali",
  },
  {
    id: "137",
    name: "Malta",
    phone_code: "356",
    label: "Malta",
  },
  {
    id: "138",
    name: "Marshall Islands",
    phone_code: "692",
    label: "Marshall Islands",
  },
  {
    id: "139",
    name: "Martinique",
    phone_code: "596",
    label: "Martinique",
  },
  {
    id: "140",
    name: "Mauritania",
    phone_code: "222",
    label: "Mauritania",
  },
  {
    id: "141",
    name: "Mauritius",
    phone_code: "230",
    label: "Mauritius",
  },
  {
    id: "142",
    name: "Mayotte",
    phone_code: "269",
    label: "Mayotte",
  },
  {
    id: "143",
    name: "Mexico",
    phone_code: "52",
    label: "Mexico",
  },
  {
    id: "144",
    name: "Micronesia",
    phone_code: "691",
    label: "Micronesia",
  },
  {
    id: "145",
    name: "Moldova",
    phone_code: "373",
    label: "Moldova",
  },
  {
    id: "146",
    name: "Monaco",
    phone_code: "377",
    label: "Monaco",
  },
  {
    id: "147",
    name: "Mongolia",
    phone_code: "976",
    label: "Mongolia",
  },
  {
    id: "148",
    name: "Montenegro",
    phone_code: null,
    label: "Montenegro",
  },
  {
    id: "149",
    name: "Montserrat",
    phone_code: "1664",
    label: "Montserrat",
  },
  {
    id: "150",
    name: "Morocco",
    phone_code: "212",
    label: "Morocco",
  },
  {
    id: "151",
    name: "Mozambique",
    phone_code: "258",
    label: "Mozambique",
  },
  {
    id: "152",
    name: "Myanmar",
    phone_code: "95",
    label: "Myanmar",
  },
  {
    id: "153",
    name: "Namibia",
    phone_code: "264",
    label: "Namibia",
  },
  {
    id: "154",
    name: "Nauru",
    phone_code: "674",
    label: "Nauru",
  },
  {
    id: "155",
    name: "Nepal",
    phone_code: "977",
    label: "Nepal",
  },
  {
    id: "156",
    name: "Netherlands",
    phone_code: null,
    label: "Netherlands",
  },
  {
    id: "157",
    name: "Netherlands Antilles",
    phone_code: "599",
    label: "Netherlands Antilles",
  },
  {
    id: "158",
    name: "New Caledonia",
    phone_code: "687",
    label: "New Caledonia",
  },
  {
    id: "159",
    name: "New Zealand",
    phone_code: "64",
    label: "New Zealand",
  },
  {
    id: "160",
    name: "Nicaragua",
    phone_code: "505",
    label: "Nicaragua",
  },
  {
    id: "161",
    name: "Niger",
    phone_code: "227",
    label: "Niger",
  },
  {
    id: "162",
    name: "Nigeria",
    phone_code: "234",
    label: "Nigeria",
  },
  {
    id: "163",
    name: "Niue",
    phone_code: "683",
    label: "Niue",
  },
  {
    id: "164",
    name: "Norfolk Island",
    phone_code: "672",
    label: "Norfolk Island",
  },
  {
    id: "165",
    name: "North Korea",
    phone_code: null,
    label: "North Korea",
  },
  {
    id: "166",
    name: "Northern Mariana Islands",
    phone_code: "1670",
    label: "Northern Mariana Islands",
  },
  {
    id: "167",
    name: "Norway",
    phone_code: "47",
    label: "Norway",
  },
  {
    id: "168",
    name: "Oman",
    phone_code: "968",
    label: "Oman",
  },
  {
    id: "169",
    name: "Pakistan",
    phone_code: "92",
    label: "Pakistan",
  },
  {
    id: "170",
    name: "Palau",
    phone_code: "680",
    label: "Palau",
  },
  {
    id: "171",
    name: "Palestinian Territory",
    phone_code: null,
    label: "Palestinian Territory",
  },
  {
    id: "172",
    name: "Panama",
    phone_code: "507",
    label: "Panama",
  },
  {
    id: "173",
    name: "Papua New Guinea",
    phone_code: "675",
    label: "Papua New Guinea",
  },
  {
    id: "174",
    name: "Paraguay",
    phone_code: "595",
    label: "Paraguay",
  },
  {
    id: "175",
    name: "Peru",
    phone_code: "51",
    label: "Peru",
  },
  {
    id: "176",
    name: "Philippines",
    phone_code: "63",
    label: "Philippines",
  },
  {
    id: "177",
    name: "Pitcairn",
    phone_code: null,
    label: "Pitcairn",
  },
  {
    id: "178",
    name: "Poland",
    phone_code: "48",
    label: "Poland",
  },
  {
    id: "179",
    name: "Portugal",
    phone_code: "351",
    label: "Portugal",
  },
  {
    id: "180",
    name: "Puerto Rico",
    phone_code: "1787",
    label: "Puerto Rico",
  },
  {
    id: "181",
    name: "Qatar",
    phone_code: "974",
    label: "Qatar",
  },
  {
    id: "182",
    name: "Republic of the Congo",
    phone_code: null,
    label: "Republic of the Congo",
  },
  {
    id: "183",
    name: "Reunion",
    phone_code: "262",
    label: "Reunion",
  },
  {
    id: "184",
    name: "Romania",
    phone_code: "40",
    label: "Romania",
  },
  {
    id: "185",
    name: "Russia",
    phone_code: "70",
    label: "Russia",
  },
  {
    id: "186",
    name: "Rwanda",
    phone_code: "250",
    label: "Rwanda",
  },
  {
    id: "187",
    name: "Saint Barthelemy",
    phone_code: null,
    label: "Saint Barthelemy",
  },
  {
    id: "188",
    name: "Saint Helena",
    phone_code: "290",
    label: "Saint Helena",
  },
  {
    id: "189",
    name: "Saint Kitts and Nevis",
    phone_code: "1869",
    label: "Saint Kitts and Nevis",
  },
  {
    id: "190",
    name: "Saint Lucia",
    phone_code: "1758",
    label: "Saint Lucia",
  },
  {
    id: "191",
    name: "Saint Martin",
    phone_code: null,
    label: "Saint Martin",
  },
  {
    id: "192",
    name: "Saint Pierre and Miquelon",
    phone_code: "508",
    label: "Saint Pierre and Miquelon",
  },
  {
    id: "193",
    name: "Saint Vincent and the Grenadines",
    phone_code: "1784",
    label: "Saint Vincent and the Grenadines",
  },
  {
    id: "194",
    name: "Samoa",
    phone_code: "684",
    label: "Samoa",
  },
  {
    id: "195",
    name: "San Marino",
    phone_code: "378",
    label: "San Marino",
  },
  {
    id: "196",
    name: "Sao Tome and Principe",
    phone_code: "239",
    label: "Sao Tome and Principe",
  },
  {
    id: "197",
    name: "Saudi Arabia",
    phone_code: "966",
    label: "Saudi Arabia",
  },
  {
    id: "198",
    name: "Senegal",
    phone_code: "221",
    label: "Senegal",
  },
  {
    id: "199",
    name: "Serbia",
    phone_code: "381",
    label: "Serbia",
  },
  {
    id: "200",
    name: "Serbia and Montenegro",
    phone_code: null,
    label: "Serbia and Montenegro",
  },
  {
    id: "201",
    name: "Seychelles",
    phone_code: "248",
    label: "Seychelles",
  },
  {
    id: "202",
    name: "Sierra Leone",
    phone_code: "232",
    label: "Sierra Leone",
  },
  {
    id: "203",
    name: "Singapore",
    phone_code: "65",
    label: "Singapore",
  },
  {
    id: "204",
    name: "Sint Maarten",
    phone_code: null,
    label: "Sint Maarten",
  },
  {
    id: "205",
    name: "Slovakia",
    phone_code: "421",
    label: "Slovakia",
  },
  {
    id: "206",
    name: "Slovenia",
    phone_code: "386",
    label: "Slovenia",
  },
  {
    id: "207",
    name: "Solomon Islands",
    phone_code: "677",
    label: "Solomon Islands",
  },
  {
    id: "208",
    name: "Somalia",
    phone_code: "252",
    label: "Somalia",
  },
  {
    id: "209",
    name: "South Africa",
    phone_code: "27",
    label: "South Africa",
  },
  {
    id: "210",
    name: "South Georgia and the South Sandwich Islands",
    phone_code: null,
    label: "South Georgia and the South Sandwich Islands",
  },
  {
    id: "211",
    name: "South Korea",
    phone_code: null,
    label: "South Korea",
  },
  {
    id: "212",
    name: "South Sudan",
    phone_code: "211",
    label: "South Sudan",
  },
  {
    id: "213",
    name: "Spain",
    phone_code: "34",
    label: "Spain",
  },
  {
    id: "214",
    name: "Sri Lanka",
    phone_code: "94",
    label: "Sri Lanka",
  },
  {
    id: "215",
    name: "Sudan",
    phone_code: "249",
    label: "Sudan",
  },
  {
    id: "216",
    name: "Suriname",
    phone_code: "597",
    label: "Suriname",
  },
  {
    id: "217",
    name: "Svalbard and Jan Mayen",
    phone_code: null,
    label: "Svalbard and Jan Mayen",
  },
  {
    id: "218",
    name: "Swaziland",
    phone_code: "268",
    label: "Swaziland",
  },
  {
    id: "219",
    name: "Sweden",
    phone_code: "46",
    label: "Sweden",
  },
  {
    id: "220",
    name: "Switzerland",
    phone_code: "41",
    label: "Switzerland",
  },
  {
    id: "221",
    name: "Syria",
    phone_code: "963",
    label: "Syria",
  },
  {
    id: "222",
    name: "Taiwan",
    phone_code: "886",
    label: "Taiwan",
  },
  {
    id: "223",
    name: "Tajikistan",
    phone_code: "992",
    label: "Tajikistan",
  },
  {
    id: "224",
    name: "Tanzania",
    phone_code: "255",
    label: "Tanzania",
  },
  {
    id: "225",
    name: "Thailand",
    phone_code: "66",
    label: "Thailand",
  },
  {
    id: "226",
    name: "Togo",
    phone_code: "228",
    label: "Togo",
  },
  {
    id: "227",
    name: "Tokelau",
    phone_code: "690",
    label: "Tokelau",
  },
  {
    id: "228",
    name: "Tonga",
    phone_code: "676",
    label: "Tonga",
  },
  {
    id: "229",
    name: "Trinidad and Tobago",
    phone_code: "1868",
    label: "Trinidad and Tobago",
  },
  {
    id: "230",
    name: "Tunisia",
    phone_code: "216",
    label: "Tunisia",
  },
  {
    id: "231",
    name: "Turkey",
    phone_code: "90",
    label: "Turkey",
  },
  {
    id: "232",
    name: "Turkmenistan",
    phone_code: "7370",
    label: "Turkmenistan",
  },
  {
    id: "233",
    name: "Turks and Caicos Islands",
    phone_code: "1649",
    label: "Turks and Caicos Islands",
  },
  {
    id: "234",
    name: "Tuvalu",
    phone_code: "688",
    label: "Tuvalu",
  },
  {
    id: "235",
    name: "U.S. Virgin Islands",
    phone_code: null,
    label: "U.S. Virgin Islands",
  },
  {
    id: "236",
    name: "Uganda",
    phone_code: "256",
    label: "Uganda",
  },
  {
    id: "237",
    name: "Ukraine",
    phone_code: "380",
    label: "Ukraine",
  },
  {
    id: "238",
    name: "United Arab Emirates",
    phone_code: "971",
    label: "United Arab Emirates",
  },
  {
    id: "239",
    name: "United Kingdom",
    phone_code: "44",
    label: "United Kingdom",
  },
  {
    id: "240",
    name: "United States",
    phone_code: "1",
    label: "United States",
  },
  {
    id: "241",
    name: "United States Minor Outlying Islands",
    phone_code: "1",
    label: "United States Minor Outlying Islands",
  },
  {
    id: "242",
    name: "Uruguay",
    phone_code: "598",
    label: "Uruguay",
  },
  {
    id: "243",
    name: "Uzbekistan",
    phone_code: "998",
    label: "Uzbekistan",
  },
  {
    id: "244",
    name: "Vanuatu",
    phone_code: "678",
    label: "Vanuatu",
  },
  {
    id: "245",
    name: "Vatican",
    phone_code: null,
    label: "Vatican",
  },
  {
    id: "246",
    name: "Venezuela",
    phone_code: "58",
    label: "Venezuela",
  },
  {
    id: "247",
    name: "Vietnam",
    phone_code: "84",
    label: "Vietnam",
  },
  {
    id: "248",
    name: "Wallis and Futuna",
    phone_code: null,
    label: "Wallis and Futuna",
  },
  {
    id: "249",
    name: "Western Sahara",
    phone_code: "212",
    label: "Western Sahara",
  },
  {
    id: "250",
    name: "Yemen",
    phone_code: "967",
    label: "Yemen",
  },
  {
    id: "251",
    name: "Zambia",
    phone_code: "260",
    label: "Zambia",
  },
  {
    id: "252",
    name: "Zimbabwe",
    phone_code: "263",
    label: "Zimbabwe",
  },
]

export const US_COUNTRY_CODE = "240"
