export const APP_INFO = {
  NAME: "5 Spheres of Fit Admin",
}

export const REGEX_VALIDATION = {
  PASSWORD_VALIDATOR: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
  WEBSITE_VALIDATOR:
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
  PHONE_NUMBER:
    /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/,
  ALPHABETS: /^[aA-zZ\s]+$/,
  NUMBERS: /^[0-9\s]+$/,
  ALPHANUMERIC: /^(?![0-9]*$)[a-zA-Z0-9" "]+$/,
  OTP: /^[0-9]+$/,
}

export const CONTENT_TYPES = {
  JSON: "application/json",
  FORM_DATA: "multipart/form-data",
}
