import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormFeedback,
  Input,
  Row,
} from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import { APP_INFO } from "constants/appConstants"
import formatBytes from "helpers/formatBytes"
import FormButton from "components/Common/FormButton"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  ArticleCategoryListApi,
  EditViewArticleApi,
  SaveArticleApi,
  updateArticle,
} from "redux/actions"
import { useDispatch } from "react-redux"
import moment from "moment"
import {
  ChallengeAddApi,
  ChallengeEditByIdApi,
  ChallengeViewByIdApi,
} from "redux/actions/challengeAction"

const ChallengeForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const handleFormSubmit = val => {
    setLoading(true)
    let result = {
      label: val.challenge,
    }
    if (id) {
      dispatch(ChallengeEditByIdApi(id, result))
        .then(value => {
          navigate("/dashboard/challenges")
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      result.category_id = Number(val.category_id)
      dispatch(ChallengeAddApi(result))
        .then(value => {
          navigate("/dashboard/challenges")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      category_id: "",
      challenge: "",
    },
    validationSchema: Yup.object({
      challenge: Yup.string().required("Please enter Challenge").max(50),
      category_id: Yup.string().required("Please select Category"),
    }),
    onSubmit: handleFormSubmit,
  })

  useEffect(() => {
    getArticleCategories()
    if (id) {
      getArticleData(id)
      document.title = "Edit Article | " + APP_INFO.NAME
    }
  }, [])

  const getArticleData = id => {
    dispatch(ChallengeViewByIdApi(id)).then(value => {
      let category_id = value.data.category_id
      let challenge = value.data.label
      validation.setFieldValue("challenge", challenge)
      validation.setFieldValue("category_id", category_id)
    })
  }
  const getArticleCategories = () => {
    dispatch(ArticleCategoryListApi())
      .then(response => setCategories(response))
      .catch(e => {})
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={id ? "Edit Challenge" : "Add new Challenge"} />
        <form
          onSubmit={e => {
            e.preventDefault()

            validation.handleSubmit()
            return false
          }}
        >
          <Card>
            <CardBody>
              <CardTitle>Challenges Information</CardTitle>
              <Row>
                <Col md={12}>
                  <div className="my-2">
                    <label className="form-label">Category Name</label>
                    <Input
                      name="category_id"
                      className="form-control"
                      readOnly={id ? true : false}
                      disabled={id ? true : false}
                      placeholder="Select category name"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.category_id || ""}
                      type="select"
                      invalid={Boolean(
                        validation.touched.category_id &&
                          validation.errors.category_id
                      )}
                    >
                      <option value="">Select Category</option>
                      {categories?.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.label}
                        </option>
                      ))}
                    </Input>
                    {validation.touched.category_id &&
                    validation.errors.category_id ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.category_id}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="my-2">
                    <label className="form-label">Challenge</label>
                    <Input
                      name="challenge"
                      className="form-control"
                      type="textarea"
                      style={{ minHeight: "10rem" }}
                      placeholder="Enter Challenge text here.."
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.challenge || ""}
                      invalid={Boolean(
                        validation.touched.challenge &&
                          validation.errors.challenge
                      )}
                    />
                    {validation.touched.challenge &&
                    validation.errors.challenge ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.challenge}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <div style={{ display: "flex" }}>
            <FormButton
              isLoading={loading}
              type="submit"
              className="btn btn-default"
              style={{
                backgroundColor: "#2A3042",
                color: "#fff",
                minWidth: "120px",
              }}
            >
              {id ? "Update" : "Create"}
            </FormButton>
            <button
              onClick={() => navigate("/dashboard/challenges")}
              type="button"
              className="btn btn-default"
              style={{
                backgroundColor: "#74788D",
                color: "#fff",
                minWidth: "120px",
                marginLeft: 15,
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default ChallengeForm
