import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  InputGroup,
} from "reactstrap"

import { useNavigate, Link } from "react-router-dom"

import * as Yup from "yup"
import { useFormik } from "formik"

import AppLogo from "assets/images/AppLogo.png"

import { connect } from "react-redux"

import { LoginApi } from "../../redux/actions/index"
import { REGEX_VALIDATION } from "constants/appConstants"
import FormButton from "components/Common/FormButton"

const Login = props => {
  const { LoginApi } = props
  const [passwordBox, setPasswordBox] = useState(true)
  document.title = "Login | The Five Spheres of Fit"

  // const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const handleLoginApi = values => {
    setLoading(true)
    LoginApi(values)
      .then(val => {
        navigate("/dashboard", { replace: true })
      })
      .catch(e => {})
      .finally(() => setLoading(false))
  }
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please Enter the valid Email")
        .required("Please Enter Your Email"),
      password: Yup.string()
        .required("Please Enter Your Password")
        .min(8, "Must be 8 characters long.")
        .matches(
          REGEX_VALIDATION.PASSWORD_VALIDATOR,
          "Invalid Password, eg: P@ssword1"
        ),
    }),
    onSubmit: handleLoginApi,
    // },
  })

  const onViewPasswordClick = () => {
    setPasswordBox(!passwordBox)
  }
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-header">
                  <div className="login-header inner-wrapper">
                    <img src={AppLogo} />
                    <h5>Welcome to</h5>
                    <h3>THE 5 SPHERES OF FIT</h3>
                    <h6>Your roadmap to personal wellness</h6>
                  </div>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <h2 className="auth-heading">Login</h2>
                      <div className="mb-3">
                        <Label className="form-label">Email Address</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <InputGroup>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type={passwordBox ? "password" : "text"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          <button
                            className="input-group-append"
                            type="button"
                            onClick={onViewPasswordClick}
                            style={{
                              border: "none",
                              padding: 0,
                              margin: 0,
                            }}
                          >
                            <span className="input-group-text">
                              {passwordBox ? (
                                <i className="mdi mdi-eye" />
                              ) : (
                                <i className="mdi mdi-eye-off" />
                              )}
                            </span>
                          </button>
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <FormButton
                          className="btn btn-dark btn-block"
                          type="submit"
                          isLoading={loading}
                        >
                          Log In
                        </FormButton>
                      </div>

                      <div className="mt-4 text-center">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate("/forget")}
                          className="text-muted lock-wrapper"
                        >
                          <i
                            style={{ fontSize: "20px" }}
                            className="mdi mdi-lock me-1"
                          />
                          Forgot your password?
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Login.propTypes = {
  history: PropTypes.object,
}

const mapStateToProps = state => ({
  isAuthenticated: state?.auth?.isAuthenticated,
})
const mapDispatchToProps = {
  LoginApi,
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
