import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  Row,
  Label,
  Input,
  FormFeedback,
  FormGroup,
} from "reactstrap"
import FormButton from "components/Common/FormButton"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useFormik } from "formik"
import * as Yup from "yup"
import { EditPageListApi, SavePageApi } from "redux/actions"
import { getHowToUseData, saveHowToUseApi } from "redux/actions/howToUseAction"
import { useDispatch } from "react-redux"
import formatBytes from "helpers/formatBytes"
import { useNavigate } from "react-router"

const HowtoUse = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [selectedImage, setSelectedImage] = useState([])
  const [selectedVideo, setSelectedVideo] = useState([])
  const [loading, setLoading] = useState(false)
  const [howToUseData, setHowToUseData] = useState({})
  const [extras, setExtras] = useState([])

  const handleHowToUseSubmit = values => {
    setLoading(true)
    let formData = new FormData()
    formData.append("title", values?.title)
    const filteredExtras = extras.filter(item => item !== "")
    formData.append("extras", JSON.stringify(filteredExtras))
    if (selectedImage.length > 0) {
      formData.append("file", selectedImage[0])
    }
    if (selectedVideo.length > 0) {
      formData.append("video", selectedVideo[0])
    }

    dispatch(saveHowToUseApi(formData))
      .catch(e => {})
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    dispatch(getHowToUseData())
      .then(val => {
        setHowToUseData({ ...val })
        setExtras(val?.extras)
      })
      .catch(e => {})
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      title: howToUseData?.title || "",
      extras: howToUseData?.extras || [],
      image_url: howToUseData?.image_url,
      video: howToUseData?.video_url,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter title"),
    }),
    onSubmit: handleHowToUseSubmit,
  })

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...extras]
    list.splice(index, 1)
    setExtras(list)
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setExtras([...extras, ""])
  }

  const handleAcceptedImage = files => {
    if (!files[0].type.includes("image")) {
      validation.errors.image = "Only image files are allowed"
      return
    } else if (files[0].size > 5000000) {
      validation.errors.image = "File size should be less than 5MB"
      return
    } else {
      delete validation.errors.image
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setSelectedImage(files)
    }
  }

  const handleAcceptedVideo = files => {
    if (files[0].type !== "video/mp4") {
      validation.errors.video = "Only .mp4 files are allowed"
      return
    } else if (files[0].size > 10000000) {
      validation.errors.video = "File size should be less than 30MB"
      return
    } else {
      delete validation.errors.video
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setSelectedVideo(files)
    }
  }

  const handleInputChange = (e, index) => {
    const { value } = e.target
    let list = [...extras] || []
    list[index] = value
    setExtras([...list])
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Edit How To Use Page" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h6 style={{ margin: 0 }}>
                          Click here to preview this page:
                        </h6>
                        <button
                          className="btn btn-primary btn-sm"
                          style={{ margin: 0, marginLeft: 5 }}
                          onClick={() =>
                            navigate("/dashboard/how-to-use/preview")
                          }
                        >
                          Preview
                        </button>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <CardTitle>Image & Video</CardTitle>
                  <Row>
                    <Col md={6}>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedImage(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bx-image-add" />
                              </div>
                              <h4>Drop file here or click to upload image.</h4>
                              <p
                                className="text-muted"
                                style={{ fontSize: 12 }}
                              >
                                Picture should be in the standard format PNG,
                                JPEG
                              </p>
                              {/* {!validation.errors.image && (
                                <p
                                  className="text-muted"
                                  style={{ fontSize: 10 }}
                                >
                                  Maximum allowed size is 5mb
                                </p>
                              )} */}
                              <p
                                className="text-danger"
                                style={{ fontSize: 12 }}
                              >
                                {validation.errors.image}
                              </p>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </Col>

                    <Col md={6}>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedVideo(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bx-video-plus" />
                              </div>
                              <h4>Drop file here or click to upload video.</h4>

                              {!validation.errors.video && (
                                <p
                                  className="text-muted"
                                  style={{ fontSize: 10 }}
                                >
                                  Maximum allowed size is 10 MB
                                </p>
                              )}
                              <p
                                className="text-danger"
                                style={{ fontSize: 12 }}
                              >
                                {validation.errors.video}
                              </p>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedImage.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2 cross-icon">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      className="avatar-lg rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <div
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </div>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    width: 30,
                                    height: 30,
                                  }}
                                  onClick={() => setSelectedImage([])}
                                >
                                  <i
                                    style={{ fontSize: 15, padding: 5 }}
                                    className="bx bxs-x-circle"
                                  ></i>
                                </div>
                              </div>
                            </Card>
                          )
                        })}
                        {selectedImage.length === 0 &&
                          validation.values.image_url && (
                            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      className="avatar-lg rounded bg-light"
                                      src={validation.values.image_url}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedVideo.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2 cross-icon">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <video
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-lg rounded bg-light"
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <div
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </div>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                                <div
                                  style={{
                                    cursor: "pointer",
                                    width: 30,
                                    height: 30,
                                  }}
                                  onClick={() => setSelectedVideo([])}
                                >
                                  <i
                                    style={{ fontSize: 15, padding: 5 }}
                                    className="bx bxs-x-circle"
                                  ></i>
                                </div>
                              </div>
                            </Card>
                          )
                        })}
                        {selectedVideo.length === 0 &&
                          validation.values.video && (
                            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <video
                                      data-dz-thumbnail=""
                                      style={{ objectFit: "cover" }}
                                      className="avatar-lg rounded bg-light"
                                      src={validation?.values?.video}
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="col-12">
                      <div className="row">
                        <div className={`col-sm-12`}>
                          <div className="row">
                            <div className="col-md-12">
                              <FormGroup>
                                <Label htmlFor="validationCustom01">
                                  Title
                                </Label>
                                <Input
                                  name="title"
                                  placeholder="Enter title"
                                  type="text"
                                  className="form-control"
                                  id="validationCustom01"
                                  // onChange={e => setPageName(e.target.value)}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.title || ""}
                                  invalid={
                                    validation.touched.title &&
                                    validation.errors.title
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.title &&
                                validation.errors.title ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.title}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div id="add_new_div" className="custom-scrollbar">
                            <div
                              className="faq-container-header"
                              style={{ background: "transparent" }}
                            >
                              <h3>Points</h3>
                              <button
                                type="button"
                                className="btn btn-success waves-effect waves-light add-faq-btn"
                                onClick={handleAddClick}
                              >
                                Add Point
                              </button>
                            </div>
                            <Card className="mt-10 p-2">
                              <Row>
                                {extras?.map((x, i) => {
                                  let inputid = `input-${i}`
                                  return (
                                    <div
                                      className=" col-md-4 col-sm-12 p-3"
                                      key={i}
                                    >
                                      <FormGroup>
                                        <div
                                          className="d-flex mb-1"
                                          style={{
                                            alignItems: "center",
                                            maxWidth: "100%",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <div>
                                            <label>{`Point ${i + 1}`}</label>
                                          </div>
                                          {i !== 0 ? (
                                            <div
                                              style={{ textAlignLast: "right" }}
                                            >
                                              <span
                                                className="btn btn-sm btn-danger waves-effect waves-light "
                                                onClick={() =>
                                                  handleRemoveClick(i)
                                                }
                                              >
                                                <i
                                                  style={{ fontSize: 20 }}
                                                  className="bx bx-trash-alt"
                                                ></i>
                                              </span>
                                            </div>
                                          ) : (
                                            <div
                                              style={{ textAlignLast: "right" }}
                                            >
                                              <span
                                                style={{ visibility: "hidden" }}
                                                className="btn btn-sm btn-danger waves-effect waves-light "
                                              >
                                                <i
                                                  style={{
                                                    fontSize: 20,
                                                    visibility: "hidden",
                                                  }}
                                                  className="bx bx-trash-alt"
                                                ></i>
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                        <Input
                                          name={inputid}
                                          className="form-control"
                                          id={inputid}
                                          placeholder="Enter Title"
                                          value={x}
                                          onChange={e =>
                                            handleInputChange(e, i)
                                          }
                                        ></Input>
                                      </FormGroup>
                                    </div>
                                  )
                                })}
                              </Row>
                            </Card>
                          </div>
                        </div>
                      </div>
                    </div>

                    <FormButton
                      type="submit"
                      className="btn btn-default"
                      style={{
                        backgroundColor: "#2A3042",
                        color: "#fff",
                        minWidth: "120px",
                      }}
                      isLoading={loading}
                    >
                      Save
                    </FormButton>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default HowtoUse
