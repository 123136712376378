import React from "react"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableContainer from "../../components/Common/TableContainer"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { columns } from "./constant.js"
import DeleteModal from "pages/Calendar/DeleteModal"
import {
  ChallengeDeleteByIdApi,
  ChallengeListApi,
} from "redux/actions/challengeAction"

const ChallengeList = () => {
  let abortController = new AbortController()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [datatableValue, setDatatableValue] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedChallenge, setSelectedChallenge] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const { challengeListing, meta } = useSelector(state => ({
    challengeListing: state.challenge.challengeListing,
    meta: state.challenge.meta,
  }))
  useEffect(() => {
    if (challengeListing?.length) {
      let data = []
      for (const item of challengeListing) {
        data.push({
          checkbox: (
            <input
              type="checkbox"
              className="form-check-input"
              id="customCheck1"
            />
          ),
          action: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                className="btn action-btn"
                onClick={() =>
                  navigate(`/dashboard/challenges/edit/${item.id}`)
                }
              >
                <i
                  style={{ fontSize: 20 }}
                  className="mdi mdi-pencil action-icon color-green"
                />
              </button>
              <button
                className="btn action-btn"
                onClick={() => toggleDeleteModal(item.id)}
              >
                <i
                  style={{ fontSize: 20 }}
                  className="mdi mdi-delete action-icon color-red"
                />
              </button>
            </div>
          ),
          sphere_name: item?.sphere?.name,
          label: item?.label,
        })
      }
      setDatatableValue(data)
    } else {
      setDatatableValue([])
    }
  }, [challengeListing])

  const toggleDeleteModal = id => {
    if (id) {
      setSelectedChallenge(id)
    } else {
      setSelectedChallenge(null)
    }
    setDeleteModal(true)
  }

  const getChallengeList = React.useCallback(
    (page = 1, limit = 10, search = "") => {
      setLoading(true)
      abortController.abort()
      abortController = new AbortController()
      dispatch(ChallengeListApi(page, limit, search, abortController.signal))
        .then(response => {
          setTimeout(() => {
            setLoading(false)
          }, 300)
        })
        .catch(e => {
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        })
    },
    []
  )

  const deleteUser = () => {
    if (selectedChallenge)
      dispatch(ChallengeDeleteByIdApi(selectedChallenge)).then(val => {
        setDeleteModal(false)
        setSelectedChallenge(null)
        getChallengeList()
      })
  }

  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs />
        <TableContainer
          searchPlaceholder={"Search Challenges"}
          columns={columns}
          data={datatableValue}
          isGlobalFilter={true}
          isAddOptions={true}
          customPageSize={10}
          pageManualData={meta}
          setManualPagination={true}
          totalPageCount={meta?.totalPages}
          initialState={{
            pageIndex: meta?.totalPages == 0 ? 0 : meta?.currentPage || 1,
            pageSize: 10,
          }}
          fetchData={getChallengeList}
          className="custom-header-css"
          isDropdown={true}
          addNewHeading="Add New Challenge"
          tableHeading="Challenge Listing"
          handleAddClicks={() => navigate("/dashboard/challenges/add")}
          loading={loading}
        />
      </div>
      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        onDeleteClick={() => deleteUser()}
      />
    </div>
  )
}

export default ChallengeList
