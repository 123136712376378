import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import { encryptTransform } from "redux-persist-transform-encrypt"

import rootReducer from "./reducers"
import { SIGN_OUT } from "./constant/authConstant"
import createSagaMiddleware from "redux-saga"
import rootSaga from "store/sagas"
import Config from "config"

const encryptor = encryptTransform({
  secretKey: Config.env().PERSIST_SECRET_KEY,
  onError: error => {},
})

const persistConfig = {
  key: "user",
  storage,
  whitelist: ["auth"],
  stateReconciler: autoMergeLevel2,
  transforms: [encryptor],
}
const sagaMiddleware = createSagaMiddleware()
const middlewares = [thunk, sagaMiddleware]
const persistedReducer = persistReducer(persistConfig, rootReducer)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const middleware = applyMiddleware(...middlewares)

const storeFactory = () => {
  let store = createStore(persistedReducer, composeEnhancers(middleware))
  let persistor = persistStore(store)

  const listener = e => {
    const { storageArea } = e
    if (store.getState().auth.isAuthenticated) {
      if (
        !storageArea.hasOwnProperty("Token") &&
        !localStorage.hasOwnProperty("Token")
      ) {
        store.dispatch({
          type: SIGN_OUT,
        })
        localStorage.clear()
      }
    }
  }
  window.addEventListener("storage", listener)
  sagaMiddleware.run(rootSaga)
  return { store, persistor }
}

let { store, persistor } = storeFactory()

export { store, persistor }
