import { appendQueryParams } from "../../utils/urlUtils"
import fetchUtil from "../../utils/fetchUtil"
import { API_METHOD, API_STATUS, API_URL } from "constants/apiResponse"

export const ArticleCategoryListApi = () => () => {
  return fetchUtil({
    url: API_URL.ARTICLE_CATEGORIES.LIST,
    method: API_METHOD.GET,
    showSuccessToast: false,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data.data.all_sphere)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const EditArticleCategoryApi = id => () => {
  return fetchUtil({
    url: API_URL.ARTICLE_CATEGORIES.EDIT + "/" + id,
    method: API_METHOD.GET,
    showSuccessToast: false,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const SaveArticleCategoryApi = body => () => {
  return fetchUtil({
    url: API_URL.ARTICLE_CATEGORIES.SAVE,
    method: API_METHOD.POST,
    body,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
