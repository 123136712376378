import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import moment from "moment"
import { APP_INFO } from "constants/appConstants"
import { useDispatch } from "react-redux"
import { EditViewArticleApi } from "redux/actions"
import { useParams } from "react-router"

const ArticleView = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  const [articleData, setArticleData] = useState({})

  useEffect(() => {
    getArticleData()
  }, [])

  useEffect(() => {
    document.title = articleData?.title + " | " + APP_INFO.NAME
  }, [articleData])

  const getArticleData = () => {
    dispatch(EditViewArticleApi(id)).then(value => {
      setArticleData(value.data)
    })
  }

  const replaceYoutubeUrl = url => {
    if (url && url.includes("watch?v=")) {
      return url.replace("watch?v=", "embed/")
    }
    return url
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title="View Article" />

        <Card>
          <CardBody>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="mb-3"
            >
              <span
                className="badge active-badge"
                style={{ marginRight: 5, fontSize: 12 }}
              >
                <i className="mdi mdi-tag" />
                {articleData?.category}
              </span>
              {articleData?.is_active ? (
                <span className="badge active-badge">Active</span>
              ) : (
                <span className="badge inactive-badge">Inactive</span>
              )}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 10,
                }}
              >
                <i className="mdi mdi-calendar action-icon color-black" />
                <p style={{ marginLeft: 5, marginBottom: 0 }}>
                  Posted At:{" "}
                  {moment(articleData?.created_at).format("DD MMM, YYYY")}
                </p>
              </div>
            </div>
            <CardTitle>{articleData?.title}</CardTitle>
            <div style={{ display: "flex", alignItems: "center" }}>
              {moment(articleData?.updated_at).isValid() && (
                <>
                  <i className="mdi mdi-calendar action-icon color-black" />
                  <p style={{ marginLeft: 5, marginBottom: 0 }}>
                    Last Updated:{" "}
                    {moment(articleData?.updated_at).format(
                      "DD MMM, YYYY HH:mm A"
                    )}
                  </p>
                </>
              )}
            </div>

            <div className="my-2">
              <Row>
                <Col md={6}>
                  <img
                    style={{
                      height: "315px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    src={articleData?.image_url}
                    className="img-fluid"
                  />
                </Col>
                <Col md={6}>
                  <iframe
                    width="560"
                    height="315"
                    src={replaceYoutubeUrl(articleData?.youtube_url)}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </Col>
              </Row>

              <p className="my-3">{articleData?.short_description}</p>
              <p
                className="my-3"
                dangerouslySetInnerHTML={{
                  __html: articleData?.description,
                }}
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default ArticleView
