import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link, useNavigate } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { connect } from "react-redux"
// action
import { ForgotPasswordApi } from "../../redux/actions/index"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import { APP_INFO } from "constants/appConstants"
import FormButton from "components/Common/FormButton"

const ForgetPassword = props => {
  //meta title
  document.title = `Forget Password | ${APP_INFO.NAME}`
  const [loading, setLoading] = useState(false)

  const { ForgotPasswordApi } = props
  const navigate = useNavigate()
  const handleForgotPassword = values => {
    setLoading(true)
    ForgotPasswordApi(values)
      .then(() => {
        navigate("/otp", { state: values })
      })
      .catch(e => {})
      .finally(() => setLoading(false))
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please Enter the valid Email")
        .required("Please Enter Your Email"),
    }),
    onSubmit: handleForgotPassword,
  })

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages-auth">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <h2 className="auth-heading">Reset Password</h2>
                      <div className="mb-3">
                        <Label className="form-label">Email Address</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-12">
                        <Col className="mt-3 d-grid">
                          <FormButton
                            isLoading={loading}
                            className="btn btn-dark w-d "
                            type="submit"
                          >
                            Reset
                          </FormButton>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({})
const mapDispatchToProps = {
  ForgotPasswordApi,
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword)
