import React from "react"
import { Link, useLocation } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = ({ title, hidePath }) => {
  const location = useLocation()
  const breadcrumb = location.pathname.split("/").slice(1)
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">
            {typeof breadcrumb[breadcrumb.length - 1] === "string" &&
              breadcrumb[breadcrumb.length - 1].length > 0 &&
              Number.isNaN(Number(breadcrumb[breadcrumb.length - 1]))
              ? title || breadcrumb[breadcrumb.length - 1].replace("-", " ")
              : title || "Dashboard"}
          </h4>
          {!hidePath && (
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                {breadcrumb?.map((item, index) => {
                  const path = breadcrumb.slice(0, index + 1).join("/")
                  if (
                    item.toLowerCase() !== "dashboard" &&
                    typeof item === "string" &&
                    item.length > 0 &&
                    Number.isNaN(Number(item))
                  ) {
                    return (
                      <BreadcrumbItem
                        key={index}
                        active={index === breadcrumb?.length - 1}
                      >
                        <Link
                          to={`/${path}`}
                          style={{ textTransform: "uppercase" }}
                        >
                          {item.replace("-", " ")}
                        </Link>
                      </BreadcrumbItem>
                    )
                  }
                })}
              </ol>
            </div>
          )}
        </div>
      </Col>
    </Row>
  )
}

export default Breadcrumb
