import React from "react"

import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Label,
  Input,
  FormFeedback,
  FormGroup,
  Button,
} from "reactstrap"
import { useFormik } from "formik"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import * as Yup from "yup"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useState } from "react"
import { useEffect } from "react"
import { connect } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { EditPageListApi, SavePageApi } from "../../redux/actions"
import FormButton from "components/Common/FormButton"

const EditPage = props => {
  const { EditPageListApi, SavePageApi } = props
  const [loading, setLoading] = useState(false)
  const [editData, setEditData] = useState({})
  const navigate = useNavigate()
  const { id } = useParams()
  useEffect(() => {
    EditPageListApi(id)
      .then(val => {
        const data = { ...val, extras: JSON.parse(val.extras) }
        setEditData(data)
      })
      .catch(e => {})
  }, [])

  const handleEditPageSubmit = values => {
    setLoading(true)
    let payload = {
      id: Number(id),
      name: values?.name,
      description: values?.description,
      extras: JSON.stringify(editData?.extras),
    }

    SavePageApi(payload)
      .catch(e => {})
      .finally(() => {
        navigate("/dashboard/pages")
        setLoading(false)
      })
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: editData?.name || "",
      description: editData?.description || "",
      extras: editData?.extras?.faq || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Page Name"),
      //   description: Yup.string().required("Please Enter Page Name"),
    }),
    onSubmit: handleEditPageSubmit,
  })

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target

    let list = [...editData?.extras?.faq] || []
    list[index] = {
      ...list[index],
      [name]: value,
    }

    setEditData({
      ...editData,
      extras: {
        faq: [...list],
      },
    })
  }

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...editData?.extras?.faq]
    list.splice(index, 1)
    setEditData({ ...editData, extras: { faq: list } })
  }

  // handle click event of the Add button
  const handleAddClick = () => {
    setEditData({
      ...editData,
      extras: {
        faq: [...editData?.extras?.faq, { name: "", content: "" }],
      },
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Edit Page" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={e => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="col-12">
                      <div className="row">
                        <div className={`col-sm-12`}>
                          <div className="row">
                            <div className="col-md-12">
                              <FormGroup>
                                <Label htmlFor="validationCustom01">Name</Label>
                                <Input
                                  name="name"
                                  placeholder="Name"
                                  type="text"
                                  className="form-control"
                                  id="validationCustom01"
                                  // onChange={e => setPageName(e.target.value)}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.name || ""}
                                  invalid={
                                    validation.touched.name &&
                                    validation.errors.name
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.name &&
                                validation.errors.name ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.name}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </div>
                          </div>

                          {!(
                            (editData?.extras?.faq &&
                              typeof editData?.extras?.faq) === "object"
                          ) && (
                            <FormGroup className="mb-3">
                              <Label>Description</Label>
                              <CKEditor
                                config={{
                                  removePlugins: ["MediaEmbed"],
                                  toolbar: [
                                    "heading",
                                    "|",
                                    "bold",
                                    "italic",
                                    "link",
                                    "bulletedList",
                                    "numberedList",
                                    "|",
                                    "outdent",
                                    "indent",
                                    "|",
                                    "blockQuote",
                                    "undo",
                                    "redo",
                                  ],
                                }}
                                editor={ClassicEditor}
                                data={validation.values.description || ""}
                                value={validation.values.description || ""}
                                name="description"
                                onReady={editor => {
                                  // You can store the "editor" and use when it is needed.
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData()
                                  validation.handleChange({
                                    target: {
                                      name: "description",
                                      value: data,
                                    },
                                  })
                                  // setEditData({ ...editData, description: data })
                                }}
                              />
                            </FormGroup>
                          )}
                        </div>

                        {(editData?.extras?.faq &&
                          typeof editData?.extras?.faq) === "object" && (
                          <div className="col-sm-12">
                            <div id="add_new_div" className="custom-scrollbar">
                              <div className="faq-container-header">
                                <h3>FAQs</h3>
                                <button
                                  type="button"
                                  className="btn btn-success waves-effect waves-light add-faq-btn"
                                  onClick={handleAddClick}
                                >
                                  Add FAQ's
                                </button>
                              </div>
                              {editData?.extras?.faq?.map((x, i) => {
                                let inputid = `input-${i}`
                                let textareaid = `textarea-${i}`
                                return (
                                  <div className="box card" key={i}>
                                    <FormGroup>
                                      <div className="row mb-1">
                                        <div className="col-10">
                                          <label>FAQ Question</label>
                                        </div>
                                        {i !== 0 ? (
                                          <div
                                            className="col-2"
                                            style={{ textAlignLast: "right" }}
                                          >
                                            <span
                                              className="btn btn-sm btn-danger waves-effect waves-light "
                                              onClick={() =>
                                                handleRemoveClick(i)
                                              }
                                            >
                                              <i className="bx bx-trash-alt"></i>
                                            </span>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <Input
                                        name={"name"}
                                        className="form-control"
                                        id={inputid}
                                        required
                                        value={x.name}
                                        onChange={e => handleInputChange(e, i)}
                                      ></Input>
                                      <label className="mt-1">
                                        Description
                                      </label>
                                      <textarea
                                        name={"content"}
                                        className="form-control"
                                        rows="2"
                                        required
                                        id={textareaid}
                                        value={x.content}
                                        onChange={e => handleInputChange(e, i)}
                                      ></textarea>
                                    </FormGroup>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <FormButton
                      type="submit"
                      className="btn btn-default"
                      style={{
                        backgroundColor: "#2A3042",
                        color: "#fff",
                        minWidth: "120px",
                      }}
                      isLoading={loading}
                    >
                      Save
                    </FormButton>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({})
const mapDispatchToProps = {
  EditPageListApi,
  SavePageApi,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPage)
