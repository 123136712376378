import { appendQueryParams } from "../../utils/urlUtils"
import fetchUtil from "../../utils/fetchUtil"
import { API_METHOD, API_STATUS, API_URL } from "constants/apiResponse"
import {
  USER_CHALLENGE_LISITING,
  USER_CHALLENGE_LISITING_META,
  USER_LISTING,
  USER_LISTING_META,
} from "redux/constant/authConstant"

export const getAllUserListApi =
  (page = 1, limit = 10, search = "", signal) =>
  dispatch => {
    return fetchUtil({
      url: appendQueryParams(API_URL.USER.ALL_USER, { page, limit, search }),
      method: API_METHOD.GET,
      showSuccessToast: false,
      signal,
    })
      .then(response => {
        if (response.statusCode == API_STATUS.OK) {
          dispatch({
            type: USER_LISTING_META,
            payload: response.data.meta,
          })
          dispatch({ type: USER_LISTING, payload: response.data.data })
          return Promise.resolve(response.data.data)
        } else {
          return Promise.reject(response.message)
        }
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }

export const getUserById = id => () => {
  return fetchUtil({
    url: API_URL.USER.VIEW_ID + "/" + id,
    method: API_METHOD.GET,
    showSuccessToast: false,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data.user)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const deleteUserById = id => () => {
  return fetchUtil({
    url: API_URL.USER.DELETE + "/" + id,
    method: API_METHOD.DELETE,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const updateUser = body => () => {
  return fetchUtil({
    url: API_URL.USER.UPDATE,
    method: API_METHOD.POST,
    body,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const getDashboardApi = () => () => {
  return fetchUtil({
    url: API_URL.USER.DASHBOARD,
    method: API_METHOD.GET,
    showSuccessToast: false,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response?.data?.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const toggleUserStatus = body => () => {
  return fetchUtil({
    url: API_URL.USER.STATUS,
    method: API_METHOD.POST,
    body,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}
export const toggleUserVerify = body => () => {
  return fetchUtil({
    url: API_URL.USER.VERIFY,
    method: API_METHOD.POST,
    body,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const BulkUserDelete = body => () => {
  return fetchUtil({
    url: API_URL.USER.BULK,
    method: API_METHOD.POST,
    body,
  })
    .then(response => {
      if (response.statusCode == API_STATUS.OK) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.message)
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export const UserChallengeList =
  (id, page = 1, limit = 10, search = "") =>
  dispatch => {
    return fetchUtil({
      url: appendQueryParams(API_URL.USER.CHALLENGE, {
        id,
        page,
        limit,
        search,
      }),
      method: API_METHOD.GET,
    })
      .then(response => {
        if (response.statusCode == API_STATUS.OK) {
          dispatch({
            type: USER_CHALLENGE_LISITING_META,
            payload: response.data.meta,
          })
          dispatch({
            type: USER_CHALLENGE_LISITING,
            payload: response.data.data,
          })
          return Promise.resolve(response.data)
        } else {
          return Promise.reject(response.message)
        }
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }
