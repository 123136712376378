import moment from "moment"
export const getMomentDate = date => {
  let result
  if (date) {
    result = moment(date)
      .utc(0)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toISOString()
  } else {
    result = moment()
      .utc(0)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toISOString()
  }
  return result
}
export const getMomentDateInstance = () => {
  return moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
}

export const addDaysToCurrentDate = (addDays, end = true) => {
  return moment(
    moment()
      .utc(0)
      .add(addDays - 1, "days")
  )
    .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
    .toISOString()
}
export const addDaysToSpecificDate = (date, addDays, start = true) => {
  if (start) {
    return moment(
      moment(date)
        .utc(0)
        .add(addDays - 1, "days")
    )
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .utc(0)
      .toISOString()
  } else {
    return moment(
      moment(date)
        .utc(0)
        .add(addDays - 1, "days")
    )
      .set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
      .utc(0)
      .toISOString()
  }
}
export const formatDate = (data, format) => {
  return moment(data).format(format)
}

export const getYearBackDate = (year = 1, format = "YYYY-MM-DD") => {
  return moment()
    .subtract(year, "year")
    .utc(0)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .format(format)
}

export const getUtcOffset = date => {
  return moment(date)
}

export const getUtcDate = date => {
  let DateFormat = moment(date).format("YYYY-MM-DD hh:mm:ss")
  let Date = moment(DateFormat)

  let finalDateLocal = moment(date).toISOString()
  let finalDateUtc = moment(Date).utc().toISOString()

  if (finalDateLocal == finalDateUtc) {
    return finalDateLocal
  } else {
    return finalDateUtc
  }
}
export const formatedDate = date => {
  return moment(date).format("YYYY-MM-DD")
}

export const overDueDate = date => {
  if (moment(date).toDate() < moment().toDate()) {
    return "Overdue"
  } else {
    return "In Complete"
  }
}

export const combineDeadlineDate = (
  deadlineDate,
  deadlineTime,
  isFormat = false
) => {
  console.log(deadlineDate, "deadlineDatedeadlineDate")
  if (deadlineTime) {
    let time = deadlineTime.split("T")
    let date = deadlineDate
    if (time?.length) {
      date = moment(date + "T" + time[1]).toDate()
    }

    if (isFormat) {
      return moment(date).format("yyyy-MM-DD hh:mm")
    } else {
      return moment(date).toDate()
    }
  } else {
    return "N/A"
  }
}

export const INVALID_DATE = "0000-00-00"
