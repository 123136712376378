import React from "react"
import { Outlet } from "react-router"

const Authmiddleware = ({ Layout }, props) => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}

export default Authmiddleware
