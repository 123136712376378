import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap"

import { Link, useLocation, useNavigate } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { connect } from "react-redux"
// action
import { OTPApi, ForgotPasswordApi } from "../../redux/actions/index"

// import images
import { APP_INFO, REGEX_VALIDATION } from "constants/appConstants"
import AuthCode from "react-auth-code-input"
import FormButton from "components/Common/FormButton"

const OTP = props => {
  //meta title
  document.title = `One Time Password | ${APP_INFO.NAME}`

  const [loading, setLoading] = useState({
    resendCode: false,
    submit: false,
  })

  const { OTPApi, ForgotPasswordApi } = props
  const navigate = useNavigate()
  const location = useLocation()
  const handleOtpPassword = values => {
    setLoading({ ...loading, submit: true })
    OTPApi(values)
      .then(() => {
        navigate("/new-password", { state: values })
      })
      .catch(e => {})
      .finally(() => setLoading({ ...loading, submit: false }))
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      token: "",
    },
    validationSchema: Yup.object({
      token: Yup.string()
        .required("Please Enter Your OTP Code")
        .matches(REGEX_VALIDATION.OTP, "OTP must contain only number")
        .min(6, "Must be exactly 6 digits")
        .max(6, "Must be exactly 6 digits"),
    }),
    onSubmit: handleOtpPassword,
  })

  const handleResendCode = () => {
    setLoading({ ...loading, resendCode: true })
    let payload = {
      email: location?.state?.email,
    }
    ForgotPasswordApi(payload)
      .then(val => {})
      .catch(e => {})
      .finally(() => setLoading({ ...loading, resendCode: false }))
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages-auth">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <h2 className="auth-heading">Verifiy Your Email</h2>
                      <FormGroup className="verification-2 mb-3">
                        <Label
                          htmlFor="digit1-input"
                          className="visually-hidden"
                        >
                          OTP Code
                        </Label>
                        <AuthCode
                          characters={6}
                          className="form-control form-control-lg text-center"
                          inputStyle={{
                            width: "50px",
                            height: "calc(1.5em + 1rem + 2px)",
                            padding: ".5rem 1rem",
                            borderRadius: "8px",
                            fontSize: "1.01562rem",
                            textAlign: "center",
                            marginRight: "15px",
                            border: "1px solid #ced4da",
                            textTransform: "uppercase",
                            borderRadius: ".4rem",
                          }}
                          containerStyle={{
                            textAlign: "center",
                          }}
                          onChange={value =>
                            validation.handleChange({
                              target: {
                                name: "token",
                                value,
                              },
                            })
                          }
                          onBlur={validation.handleBlur}
                          value={validation.values.token || ""}
                          invalid={
                            validation.touched.token && validation.errors.token
                              ? true
                              : false
                          }
                        />
                        {validation.touched.token && validation.errors.token ? (
                          <FormFeedback
                            type="invalid"
                            style={{ display: "block" }}
                          >
                            {validation.errors.token}
                          </FormFeedback>
                        ) : null}
                      </FormGroup>
                      <Row className="mb-12">
                        <Col className="d-grid">
                          <FormButton
                            onClick={handleResendCode}
                            className="btn btn-dark w-md "
                            isLoading={loading?.resendCode}
                          >
                            Resend Code
                          </FormButton>
                        </Col>
                      </Row>
                      <Row className="mb-12">
                        <Col className="mt-3 d-grid">
                          <FormButton
                            className="btn btn-dark w-md "
                            type="submit"
                            isLoading={loading?.submit}
                          >
                            Submit
                          </FormButton>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({})
const mapDispatchToProps = {
  OTPApi,
  ForgotPasswordApi,
}
export default connect(mapStateToProps, mapDispatchToProps)(OTP)
