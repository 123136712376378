export const columns = [
  {
    Header: "Category Name",
    accessor: "category",
    style: { fontWeight: "bold" }
  },
  {
    Header: "Article Title",
    accessor: "title",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Created At",
    accessor: "created_at",
  },
  {
    Header: "Status",
    accessor: "is_active",
  },
  {
    Header: "Action",
    accessor: "action",
  },
]

export const articleColumns = [
  {
    Header: "Checkbox",
    accessor: "checkbox",
  },
  {
    Header: "Category Name",
    accessor: "category",
    style: { fontWeight: "bold" }
  },
  {
    Header: "Article Title",
    accessor: "title",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Created At",
    accessor: "created_at",
  },
  {
    Header: "Status",
    accessor: "is_active",
  },
  {
    Header: "Action",
    accessor: "action",
  },
]
