import { API_STATUS } from "constants/apiResponse"
import { SIGN_OUT } from "redux/actions"
import { store } from "redux/store"
import { ErrorToaster, SuccessToastr } from "./loggerUtils"

export const handleFetchError = async (res, showSuccessToast) => {
  let resp = await res.json()
  if (
    resp.statusCode >= API_STATUS.BAD_REQUEST &&
    resp.statusCode < API_STATUS.SERVER_ERROR
  ) {
    if (resp?.statusCode === API_STATUS.UNAUTHORIZED) {
      // store.dispatch({ type: SIGN_OUT })
      ErrorToaster(resp.message || `Your Session has been Expired!`)
      localStorage.clear()
      window.location.href = "/"
    } else {
      ErrorToaster(`${resp.message || "Your Session has been Expired!"}`)
    }
    throw resp
  }
  if (resp?.statusCode === API_STATUS.OK) {
    if (
      resp?.message &&
      typeof resp?.message === "string" &&
      showSuccessToast
    ) {
      SuccessToastr(`${resp.message || "Success"}`)
    }
  }
  return resp
}
