import React, { Fragment, memo, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import { Table, Row, Col, Button, Input, Spinner } from "reactstrap"

// Define a default UI for filtering
function GlobalFilter({
  pageIndex,
  pageSize,
  fetchData,
  setValue,
  value,
  searchPlaceholder,
  gotoPage,
}) {
  const onChange = useAsyncDebounce(value => {
    gotoPage()
    fetchData(pageIndex, pageSize, value)
  }, 500)

  return (
    <div className="search-box me-2 mb-2 d-inline-block">
      <div className="position-relative">
        <label
          htmlFor="search-bar-0"
          className="search-label"
          style={{ width: "100%" }}
        >
          <span id="search-bar-0-label" className="sr-only">
            Search this table
          </span>
          <input
            onChange={e => {
              setValue(e.target.value)
              onChange(e.target.value)
            }}
            id="search-bar-0"
            type="text"
            className="form-control"
            placeholder={searchPlaceholder}
            style={{ borderRadius: "6px" }}
            value={value || ""}
          />
        </label>
        <i className="bx bx-search-alt search-icon"></i>
      </div>
    </div>
  )
}

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  isAddOptions,
  handleAddClicks,
  className,
  isDropdown,
  onDropdownChange,
  dropdownValue,
  dropdownItems = [],
  addNewHeading,
  tableHeading,
  showLimitTab = true,
  showPagination = true,
  sortColumnAvailable = false,
  setManualPagination = false,
  fetchData,
  initialState,
  totalPageCount,
  pageNumberSelection = false,
  onCheckAllRows,
  selectedRows,
  onDeleteSelected,
  loading,
  searchPlaceholder = "Records ...",
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: setManualPagination,
      pageCount: -1,
      defaultColumn: { Filter: false },
      initialState: initialState,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )
  const [value, setValue] = React.useState("")
  React.useEffect(() => {
    if (setManualPagination) fetchData(pageIndex, pageSize, value)
    if (document.querySelector("#customCheck1")) {
      document.querySelector("#customCheck1").checked = false
    }
  }, [fetchData, pageIndex, pageSize])

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
    gotoPage(1)
  }

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0
    gotoPage(page)
  }
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="mb-2">
          {tableHeading && (
            <h2 style={{ fontSize: "15px", fontWeight: "600" }}>
              {tableHeading}
            </h2>
          )}
        </div>
        <div
          className="mb-2"
          style={{ display: "flex", width: "70%", justifyContent: "end" }}
        >
          {onDeleteSelected && (
            <div className="me-2">
              <div className="text-sm-end">
                <Button
                  type="button"
                  style={{
                    backgroundColor:
                      selectedRows?.length > 0 ? "#2A3042" : "#A6B0CF",
                    borderColor:
                      selectedRows?.length > 0 ? "#2A3042" : "#A6B0CF",
                  }}
                  className="mb-2 me-2"
                  onClick={onDeleteSelected}
                  disabled={!selectedRows?.length}
                >
                  <i className="bx bx-trash me-1" />
                  Delete Selected
                </Button>
              </div>
            </div>
          )}

          {isGlobalFilter && (
            <GlobalFilter
              pageIndex={pageIndex}
              pageSize={pageSize}
              fetchData={fetchData}
              setValue={setValue}
              value={value}
              gotoPage={() => gotoPage(1)}
              searchPlaceholder={searchPlaceholder}
            />
          )}
          {isDropdown && dropdownItems.length > 0 && (
            <div className="me-2">
              <select
                value={dropdownValue}
                onChange={onDropdownChange}
                className="form-control"
              >
                {dropdownItems?.map((item, index) => (
                  <option key={index} value={item.value || item}>
                    {item.label || item}
                  </option>
                ))}
              </select>
            </div>
          )}

          {showLimitTab && (
            <div className="me-2">
              <select
                className="form-select"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          )}
          {isAddOptions && (
            <div className="me-2">
              <div className="text-sm-end">
                <Button
                  type="button"
                  style={{ backgroundColor: "#2A3042" }}
                  className="mb-2 me-2"
                  onClick={handleAddClicks}
                >
                  <i className="mdi mdi-plus me-1" />
                  {addNewHeading ? addNewHeading : "Add New"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups &&
              headerGroups.map(headerGroup => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      key={column.id}
                      style={{
                        cursor: "pointer",
                        background: "#F8F9FA",
                        color: "#000000",
                        border: "none",
                        width:
                          column.render("Header") === "Checkbox"
                            ? "50px"
                            : "unset",
                      }}
                    >
                      {column.render("Header") === "Checkbox" ? (
                        <input
                          type="checkbox"
                          className="form-check-input header-checkbox"
                          id="customCheck1"
                          onChange={e => onCheckAllRows(e, pageIndex)}
                        />
                      ) : (
                        <div
                          {...(sortColumnAvailable && {
                            ...column.getSortByToggleProps(),
                          })}
                          style={{ minHeight: 0, cursor: "default" }}
                        >
                          {column.render("Header")}
                          {generateSortingIndicator(column)}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
          </thead>

          <tbody {...getTableBodyProps()} style={{ background: "white" }}>
            {!loading &&
              page &&
              page.map(row => {
                prepareRow(row)
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => {
                        return (
                          <td
                            key={cell.id}
                            {...cell.getCellProps()}
                            style={{
                              verticalAlign: "middle",
                              minWidth:
                                cell.column.id === "created_at"
                                  ? "120px"
                                  : "unset",
                              ...cell.column.style,
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        )
                      })}
                    </tr>
                  </Fragment>
                )
              })}
          </tbody>
        </Table>
        {loading && (
          <div style={{ width: "100%", height: 475 }}>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "100%" }}
            >
              <Spinner animation="border" variant="primary" />
            </div>
          </div>
        )}
        {!loading && page.length === 0 && (
          <div style={{ display: "flex" }}>
            <img
              style={{ width: "200px", margin: "40px auto 70px" }}
              src={require("../../assets/images/no-record.png")}
            />
          </div>
        )}
      </div>

      {showPagination && (
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <button
                color="primary"
                className="btn btn-link"
                style={{
                  color: "#74788D",
                  fontSize: "18px",
                }}
                onClick={() => gotoPage(1)}
                disabled={!canPreviousPage || initialState.pageIndex == 1}
              >
                {"<<"}
              </button>
              <button
                className="btn btn-link"
                style={{
                  color: "#74788D",
                  fontSize: "18px",
                }}
                onClick={previousPage}
                disabled={!canPreviousPage || pageIndex == 1}
              >
                {"<"}
              </button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pageIndex}
              {" of "}
              {setManualPagination ? totalPageCount : pageOptions.length}
            </strong>
          </Col>

          {pageNumberSelection && (
            <Col className="col-md-auto">
              <Input
                type="number"
                min={1}
                style={{ width: 70 }}
                max={pageOptions.length}
                defaultValue={pageIndex}
                onChange={onChangeInInput}
              />
            </Col>
          )}

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <button
                className="btn btn-link"
                style={{
                  color: "#74788D",
                  fontSize: "18px",
                }}
                color="primary"
                onClick={nextPage}
                disabled={!canNextPage || pageIndex === totalPageCount}
              >
                {">"}
              </button>
              <button
                className="btn btn-link"
                style={{
                  color: "#74788D",
                  fontSize: "18px",
                }}
                color="primary"
                onClick={() => gotoPage(totalPageCount)}
                disabled={!canNextPage || pageIndex === totalPageCount}
              >
                {">>"}
              </button>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  )
}

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default memo(TableContainer)
