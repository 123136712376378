import React from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router"

const NoAuth = ({ children }) => {
  //token and isAuthenticated from redux
  const { isAuthenticated, token } = useSelector(state => state.auth)

  if (!isAuthenticated && !token) {
    return children
  } else {
    return <Navigate to={"/dashboard"} replace />
  }
}
export default NoAuth
