import React from "react"
import { useSelector } from "react-redux"
import VerticalLayout from "./VerticalLayout/"
import HorizontalLayout from "./HorizontalLayout/"

const Layout = ({ children }) => {
  const layout = useSelector(state => state.Layout)
  if (layout?.layoutType === "horizontal") {
    return <HorizontalLayout>{children}</HorizontalLayout>
  } else {
    return <VerticalLayout>{children}</VerticalLayout>
  }
}

export default Layout
