import React, { useEffect, useRef, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Dropzone from "react-dropzone"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap"
import { Link, useNavigate, useParams } from "react-router-dom"
import { APP_INFO, REGEX_VALIDATION } from "constants/appConstants"
import FormButton from "components/Common/FormButton"
import formatBytes from "helpers/formatBytes"
import * as Yup from "yup"
import { useFormik } from "formik"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { registerUserApi, updateUserApi } from "redux/actions/userActions"
import gendersArray from "constants/gendersArray"
import countriesArray from "constants/countriesArray"
import { statesArray } from "constants/statesArray"
import { getUserById } from "redux/actions/userAction"
import InputMask from "react-input-mask"
import moment from "moment"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { formatedDate, INVALID_DATE } from "utils/dateUtil"
const UserForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()

  const [defaultValues, setDefaultValues] = useState({
    email: "",
    password: "",
    phone: "",
    confirm_password: "",
    name: "",
    dob: "",
    city_name: "",
  })

  const [loading, setLoading] = useState({
    saveAndNew: false,
    saveAndExit: false,
  })
  const [saveAndNew, setSaveAndNew] = useState(false)
  const [passwordBox, setPasswordBox] = useState({
    password: true,
    confirm_password: true,
  })
  const handleFormSubmit = val => {
    console.log(val, "valvalval")
    if (saveAndNew) {
      setLoading({ ...loading, saveAndNew: true })
    } else {
      setLoading({ ...loading, saveAndExit: true })
    }
    let formData = new FormData()
    for (let key in val) {
      if (key === "dob") {
        val[key] = formatedDate(val[key])
      }
      formData.append(key, val[key])
    }
    if (selectedFiles.length > 0) {
      formData.append("file", selectedFiles[0])
    }
    if (id) {
      formData.append("id", id)
      dispatch(updateUserApi(formData))
        .then(response => {
          navigate("/dashboard/user-management")
        })
        .catch(() => {})
        .finally(() => {
          setLoading({ saveAndNew: false, saveAndExit: false })
        })
    } else {
      registerUserApi(formData)
        .then(response => {
          if (saveAndNew) {
            removeErrors()
            validation.resetForm()
          } else {
            navigate("/dashboard/user-management")
          }
        })
        .catch(error => {})
        .finally(() => {
          setLoading({ saveAndNew: false, saveAndExit: false })
        })
    }
  }

  const [selectedFiles, setselectedFiles] = useState([])

  const handleAcceptedFiles = files => {
    if (!files[0].type.includes("image")) {
      validation.errors.file = "Only image files are allowed"
      return
    } else if (files[0].size > 5000000) {
      validation.errors.file = "File size should be less than 5MB"
      return
    } else {
      delete validation.errors.file
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      )
      setselectedFiles(files)
    }
  }

  useEffect(() => {
    if (id) {
      getUserData(id)
      document.title = "Edit Article | " + APP_INFO.NAME
    }
  }, [])

  const getUserData = id => {
    dispatch(getUserById(id)).then(value => {
      setDefaultValues({ ...value.personal, ...value.detail })
    })
  }

  const validateErrors = () => {
    validation.setTouched({
      ...validation.touched,
      name: true,
      email: true,
      dob: true,
      city_name: true,
      gender: true,
      country_id: true,
      state_id: true,
      phone: true,
      password: true,
      confirm_password: true,
    })
  }

  const removeErrors = () => {
    validation.setTouched({
      ...validation.touched,
      name: false,
      email: false,
      dob: false,
      city_name: false,
      gender: false,
      country_id: false,
      phone: false,
      state_id: false,
      password: false,
      confirm_password: false,
    })
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { ...defaultValues },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      email: Yup.string()
        .email("Please enter valid email address.")
        .required("Please enter email"),
      dob: Yup.string()
        .required("Please select date of birth.")
        .test("dob", "Date of birth should be at least 5 years old", value => {
          if (value) {
            let today = new Date()
            let birthDate = new Date(value)
            let age = today.getFullYear() - birthDate.getFullYear()
            let m = today.getMonth() - birthDate.getMonth()
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
              age--
            }
            return age >= 5
          }
          return true
        }),
      city_name: Yup.string().required("Please enter city"),
      country_id: Yup.string().required("Please select country"),
      gender: Yup.string().required("Please select gender"),
      state_id: Yup.string().required("Please select state"),
      phone: Yup.string().matches(REGEX_VALIDATION.PHONE_NUMBER, {
        message: "Please enter valid number.",
        excludeEmptyString: true,
      }),
      password: id
        ? Yup.string()
            .min(8, "Must be 8 characters long.")
            .matches(
              REGEX_VALIDATION.PASSWORD_VALIDATOR,
              "Invalid password, eg: P@ssword1"
            )
        : Yup.string()
            .required("Please enter password")
            .min(8, "Must be 8 characters long.")
            .matches(
              REGEX_VALIDATION.PASSWORD_VALIDATOR,
              "Invalid password, eg: P@ssword1"
            ),
      confirm_password: id
        ? Yup.string().oneOf([Yup.ref("password")], "Passwords must match.")
        : Yup.string()
            .required(`Please confirm password`)
            .oneOf([Yup.ref("password")], "Passwords must match."),
    }),
    onSubmit: handleFormSubmit,
  })
  const onViewPasswordClick = key => {
    setPasswordBox({
      ...passwordBox,
      [key]: !passwordBox[key],
    })
  }
  return (
    <div className="page-content">
      <div className="container-fluid">
        <Breadcrumbs title={id ? "Edit User" : "Add new User"} />
        <form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Card>
            <CardBody>
              <CardTitle>User Images</CardTitle>
              <Dropzone
                onDrop={acceptedFiles => {
                  handleAcceptedFiles(acceptedFiles)
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone">
                    <div
                      className="dz-message needsclick mt-2"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="mb-3">
                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h4>Drop files here or click to upload profile.</h4>
                      <p className="text-muted" style={{ fontSize: 12 }}>
                        Picture should be in the standard format PNG, JPEG
                      </p>
                      <p className="text-danger" style={{ fontSize: 12 }}>
                        {validation.errors.file}
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
              <Row>
                <Col md="6">
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2 cross-icon">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-lg rounded bg-light"
                                  alt={f.name}
                                  src={f.preview}
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                            <div
                              style={{
                                cursor: "pointer",
                                width: 30,
                                height: 30,
                              }}
                              onClick={() => setselectedFiles([])}
                            >
                              <i
                                style={{ fontSize: 15, padding: 5 }}
                                className="bx bxs-x-circle"
                              ></i>
                            </div>
                          </div>
                        </Card>
                      )
                    })}
                    {selectedFiles.length === 0 &&
                      validation.values.image_url && (
                        <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <img
                                  data-dz-thumbnail=""
                                  height="80"
                                  className="avatar-lg rounded bg-light"
                                  src={validation.values.image_url}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle>Basic Information</CardTitle>
              <Row>
                <Col lg={6}>
                  <div className="my-2">
                    <Label className="form-label">Name</Label>
                    <Input
                      name="name"
                      className="form-control"
                      placeholder="Enter name"
                      onChange={validation.handleChange}
                      maxLength={20}
                      onBlur={validation.handleBlur}
                      value={validation.values.name || ""}
                      invalid={Boolean(
                        validation.touched.name && validation.errors.name
                      )}
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="my-2">
                    <Label className="form-label">Email Address</Label>
                    <Input
                      name="email"
                      className="form-control"
                      placeholder="Enter email address"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      disabled={Boolean(id)}
                      invalid={Boolean(
                        validation.touched.email && validation.errors.email
                      )}
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="my-2">
                    <Label className="form-label">Phone Number</Label>
                    <InputMask
                      name="phone"
                      // type={"number"}
                      mask="(999) 999-9999"
                      placeholder="Enter phone number"
                      className="form-control input-color"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone || ""}
                      style={{
                        borderColor:
                          validation.touched.phone && validation.errors.phone
                            ? "red"
                            : "",
                      }}
                    />
                    {validation.touched.phone && validation.errors.phone ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.phone}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="my-2">
                    <Label className="form-label">Date Of Birth</Label>
                    {/* <Input
                      name="dob"
                      className="form-control"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.dob || ""}
                      invalid={Boolean(
                        validation.touched.dob && validation.errors.dob
                      )}
                      onKeyDown={e => e.preventDefault()}
                      type="date"
                      max={moment().subtract(5, "years").format("YYYY-MM-DD")}
                    /> */}
                    <DatePicker
                      showIcon={true}
                      showPopperArrow={false}
                      showDisabledMonthNavigation={false}
                      showYearDropdown
                      className="form-control"
                      maxDate={moment().subtract(5, "years").toDate()}
                      onKeyDown={e => e.preventDefault()}
                      selected={
                        validation.values.dob &&
                        validation.values.dob != INVALID_DATE
                          ? moment(validation.values.dob).toDate()
                          : moment().subtract(5, "years").toDate()
                      }
                      onChange={date =>
                        validation.handleChange({
                          target: { name: "dob", value: date },
                        })
                      }
                      onBlur={date =>
                        validation.handleBlur({
                          target: { name: "dob", value: date },
                        })
                      }
                    />
                    {validation.touched.dob && validation.errors.dob ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.dob}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="my-2">
                    <label className="form-label">Gender</label>
                    <Input
                      name="gender"
                      className="form-control"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.gender || ""}
                      invalid={Boolean(
                        validation.touched.gender && validation.errors.gender
                      )}
                      style={{
                        borderColor:
                          validation.touched.gender && validation.errors.gender
                            ? "red"
                            : "",
                      }}
                      type="select"
                      placeholder="Select gender"
                    >
                      <option value={""}>Select Gender</option>
                      {gendersArray.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.label}
                        </option>
                      ))}
                    </Input>
                    {validation.touched.gender && validation.errors.gender ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.gender}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="my-2">
                    <label className="form-label">Country</label>
                    <Input
                      name="country_id"
                      className="form-control"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.country_id || ""}
                      invalid={Boolean(
                        validation.touched.country_id &&
                          validation.errors.country_id
                      )}
                      type="select"
                      style={{
                        borderColor:
                          validation.touched.country_id &&
                          validation.errors.country_id
                            ? "red"
                            : "",
                      }}
                      placeholder="Select country"
                    >
                      <option value={""}>Select Country</option>
                      {countriesArray.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.label}
                        </option>
                      ))}
                    </Input>
                    {validation.touched.country_id &&
                    validation.errors.country_id ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.country_id}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="my-2">
                    <label className="form-label">State</label>
                    <Input
                      name="state_id"
                      className="form-control"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.state_id || ""}
                      invalid={Boolean(
                        validation.touched.state_id &&
                          validation.errors.state_id
                      )}
                      type="select"
                      style={{
                        borderColor:
                          validation.touched.state_id &&
                          validation.errors.state_id
                            ? "red"
                            : "",
                      }}
                      placeholder="Select state"
                    >
                      <option value={""}>Select State</option>
                      {statesArray.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.label}
                        </option>
                      ))}
                    </Input>
                    {validation.touched.state_id &&
                    validation.errors.state_id ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.state_id}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="my-2">
                    <label className="form-label">City</label>
                    <Input
                      name="city_name"
                      className="form-control"
                      placeholder="Enter city"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.city_name || ""}
                      invalid={Boolean(
                        validation.touched.city_name &&
                          validation.errors.city_name
                      )}
                    />
                    {validation.touched.city_name &&
                    validation.errors.city_name ? (
                      <FormFeedback type="invalid" style={{ display: "block" }}>
                        {validation.errors.city_name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="my-2">
                    <label className="form-label">Password</label>
                    <InputGroup>
                      <Input
                        name="password"
                        className="form-control"
                        placeholder="Create password"
                        type={passwordBox?.password ? "password" : "text"}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                        invalid={Boolean(
                          validation.touched.password &&
                            validation.errors.password
                        )}
                      />
                      <button
                        className="input-group-append"
                        type="button"
                        onClick={() => onViewPasswordClick("password")}
                        style={{
                          border: "none",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <span className="input-group-text">
                          {passwordBox?.password ? (
                            <i className="mdi mdi-eye" />
                          ) : (
                            <i className="mdi mdi-eye-off" />
                          )}
                        </span>
                      </button>
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback
                          type="invalid"
                          style={{ display: "block" }}
                        >
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="my-2">
                    <label className="form-label">Confirm Password</label>
                    <InputGroup>
                      <Input
                        name="confirm_password"
                        className="form-control"
                        type={
                          passwordBox?.confirm_password ? "password" : "text"
                        }
                        placeholder="Confirm password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.confirm_password || ""}
                        invalid={Boolean(
                          validation.touched.confirm_password &&
                            validation.errors.confirm_password
                        )}
                      />
                      <button
                        className="input-group-append"
                        type="button"
                        onClick={() => onViewPasswordClick("confirm_password")}
                        style={{
                          border: "none",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <span className="input-group-text">
                          {passwordBox?.confirm_password ? (
                            <i className="mdi mdi-eye" />
                          ) : (
                            <i className="mdi mdi-eye-off" />
                          )}
                        </span>
                      </button>
                      {validation.touched.confirm_password &&
                      validation.errors.confirm_password ? (
                        <FormFeedback
                          type="invalid"
                          style={{ display: "block" }}
                        >
                          {validation.errors.confirm_password}
                        </FormFeedback>
                      ) : null}
                    </InputGroup>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <div style={{ display: "flex", marginBottom: 20 }}>
            <FormButton
              type="button"
              className="btn btn-default"
              style={{
                backgroundColor: "#2A3042",
                color: "#fff",
                minWidth: "120px",
              }}
              disabled={loading?.saveAndNew || loading?.saveAndExit}
              isLoading={loading?.saveAndNew}
              onClick={() => {
                validation.submitForm()
                validateErrors()
                setSaveAndNew(true)
              }}
            >
              {id ? "Save Changes" : "Save and New"}
            </FormButton>
            {!id && (
              <FormButton
                type="button"
                disabled={loading?.saveAndNew || loading?.saveAndExit}
                isLoading={loading?.saveAndExit}
                className="btn btn-default"
                style={{
                  backgroundColor: "#74788D",
                  color: "#fff",
                  minWidth: "120px",
                  marginLeft: 15,
                }}
                onClick={() => {
                  validation.submitForm()
                  validateErrors()
                  setSaveAndNew(false)
                }}
              >
                Save and Exit
              </FormButton>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}
export default UserForm
